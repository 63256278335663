import React, { useState, useCallback, useEffect } from "react";
import { APIProvider, Map, MapControl, useMap,  } from "@vis.gl/react-google-maps";
import { LatLng } from "./types";
import MapControlWithDrawing from "./MapControl";
import { MapHandler } from "./MapHandler";
import { PlaceAutocompleteClassic } from "./PlaceAutoComplete";

interface GoogleMapCoreInterface {
  selectedMarkerPosition: LatLng | null;
  setSelectedMarkerPosition: (coords: LatLng) => void;
}

const GoogleMapCore : React.FC<GoogleMapCoreInterface> = ({ selectedMarkerPosition, setSelectedMarkerPosition }) => {
  const map = useMap();
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>();

  const handleSelectedPlace = (place:any) => {
    setSelectedPlace(place);
  }

  return(
    <>
      <PlaceAutocompleteClassic onPlaceSelect={handleSelectedPlace}/>
      <br/>
      <Map
      defaultZoom={9}
      defaultCenter={{ lat: 41.594197929760774, lng: -93.6171048091782 }}
      mapTypeId={'hybrid'}
      gestureHandling={"greedy"}
      disableDefaultUI={true}
      mapTypeControl
      zoomControl
      style={{
        height:'400px',
        width:'400px'
      }}
      />
      <MapHandler place={selectedPlace ?? null} map={map} />
      
      <MapControlWithDrawing 
      setSelectedMarkerPosition={setSelectedMarkerPosition} 
      map={map}/>
      
      <p style={{ textAlign: 'center' }}>Lat: {selectedMarkerPosition?.lat}, Long: {selectedMarkerPosition?.lng}</p>
    </>
  )
}

const GoogleMapWithPinDrop : React.FC<GoogleMapCoreInterface> = ({ selectedMarkerPosition, setSelectedMarkerPosition }) => {

  
  return(
    <APIProvider apiKey={$googleMapsApiKey ?? ""}>
        <GoogleMapCore selectedMarkerPosition={selectedMarkerPosition} setSelectedMarkerPosition={setSelectedMarkerPosition}/>
    </APIProvider>
  )
};

export default GoogleMapWithPinDrop;
