import { Button, Carousel, Form, Input, Modal, notification, Row, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import GoogleMapWithPinDrop from "./GoogleMaps/GoogleMapWithPinDrop"
import { LatLng } from "./GoogleMaps/types";
import RegisterProductDTO from "src/models/RegisterProductDTO";
import { CarouselRef } from "antd/es/carousel";
import BinApiService from "src/api/BinApiService";
import { CheckCircleOutlined } from "@ant-design/icons";
import RoleUtil from "src/utils/RoleUtil";
import GrowerSelection from "src/pages/dashboard/HomeScreen/GrowerSelection";
import { GrowerSlim } from "src/redux/actions/UserSessionActions";
import { useGetAllGrowersQuery } from "src/queries/useGetAllGrowersQuery";

interface RegisterProductModalProps {
    modalOpen: boolean;
    closeModal: () => void;
    growerId: number;
}

const RegisterProductModal : React.FC<RegisterProductModalProps> = ({ modalOpen, closeModal,  growerId }) => {
    
    const [content, setContent]=useState(1);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [msg, setMsg] = useState<React.ReactNode>(<></>);
    const [msgLoading, setMsgLoading] = useState(false);
    const [selectedMarkerPosition, setSelectedMarkerPosition] = useState<LatLng | null>(null);
    const [loading, setLoading] = useState(false);
    const allGrowersQuery = useGetAllGrowersQuery({ enabled: true, refetchOnWindowFocus: false });

    const allGrowerSelectOptions = [
        { value: 0, label: 'Select a grower' },
        ...(allGrowersQuery.data ?? []).map( (g) => (
            { value: g.growerID, label: g.growerName }
        ))
    ]

    const [form] = Form.useForm<RegisterProductDTO>();
    const values = Form.useWatch([], { form, preserve: true });
    const carouselRef = React.createRef<CarouselRef>();
    const isAdmin = RoleUtil.currentUserIsAdmin();

    const searchDeviceId = async () => {
        const deviceId = values?.deviceId;
        if(deviceId == '' || deviceId == null){
            setMsg(<p style={{ textAlign:'center', color:'red' }}>
                Please type in your device ID
            </p>);
            return;
        }else{
            try{
                setMsgLoading(true);
                const result = await BinApiService.pingPremierPlusDevice(deviceId);
                if(result == true){
                    setDisableNextButton(false);
                    setMsg(
                    <Row justify={'center'} align={'middle'}>
                        <CheckCircleOutlined
                        style={{
                            color: "green",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            fontSize: "24px",
                            padding: "4px",   
                        }}
                        />
                        Item found
                    </Row>);
                }else{
                    setMsg(<p style={{ textAlign:'center', color:'red' }}>Could not reach device. Please make sure it's online and spelled correctly</p>);
                }
                
            }catch(err){
                setMsg(<p style={{ textAlign:'center', color:'red' }}>Error reaching server, please reach out to contact support</p>);
            }finally{
                setMsgLoading(false);
            }
        }
    }

    const handleCloseModal = () =>{

        form.resetFields();
        setSelectedMarkerPosition(null);
        setMsg(<></>);
        setDisableNextButton(true);
        handleResetCarousel();
        closeModal();
    }

    const onRegisterProductSubmit = async ( values: RegisterProductDTO ) => {

        try{
            setLoading(true);
            if(selectedMarkerPosition == null){
                notification.error({
                    message:"Please find your bin on the map"
                })
                setLoading(false);
                return;
            }
            values.latitude = selectedMarkerPosition?.lat ?? 0;
            values.longitude = selectedMarkerPosition?.lng ?? 0;
            const result = await BinApiService.registerProduct(values);
            notification.success({ message: 'Successfully registered product' });
            setLoading(false);
            handleCloseModal();
        }catch(error){
            notification.error({
                message: "Could not register product",
                description: error?.errorDetails?.detail ?? "Contact support",
            });
            setLoading(false);
        }
    }

    const handleClickNext = () => {
        setContent((prev) => Math.max(prev + 1, 2));
        carouselRef?.current?.next();
    }

    const handleClickPrev = () => {
        setContent((prev) => Math.max(prev - 1, 1));
        carouselRef?.current?.prev();
    }

    const handleResetCarousel = () => {
        setContent(1);
        carouselRef?.current?.goTo(1);
    }

    return(
        <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        okButtonProps={{ style: { display:'none' } }}
        cancelButtonProps={{ style: { display:'none' } }}
        destroyOnClose={true}
        >
            <Spin spinning={loading}>
                <Form 
                form={form}
                onFinish={onRegisterProductSubmit}
                style={{
                    padding:'32px'
                }}>
                    <Carousel
                    infinite={false} 
                    dots={false} 
                    draggable={false} 
                    ref={carouselRef}
                    >
                        <div>
                            <h2>Register your product</h2>
                            <Form.Item name={'growerId'} initialValue={growerId} hidden/>
                            <Form.Item
                            label={'Device ID'}
                            labelCol={{ span:24 }}
                            name={'deviceId'}
                            rules={[
                                { required: true },
                            ]}
                            >
                                <Input placeholder="Please search a device ID...."/>
                            </Form.Item>
                            <Row justify={'center'}>
                                <Button 
                                onClick={searchDeviceId}
                                type='primary'>
                                    Search
                                </Button>
                            </Row>

                            {
                                isAdmin && 
                                    <Form.Item
                                    label={'Grower ID'}
                                    labelCol={{ span:24 }}
                                    name={'growerId'}
                                    rules={[
                                        { required: true },
                                    ]}
                                    >
                                        <Select 
                                        options={allGrowerSelectOptions}
                                        placeholder='Select a grower...'
                                        />
                                    </Form.Item>
                            }
                            <br/>

                            <Spin spinning={msgLoading}>
                                {msg}
                            </Spin>
                            
                        </div>

                        <div>
                            <Form.Item
                            label={<p>Place a <u style={{ fontWeight:'bold', }}>marker</u> on your system's location on the map below</p>}
                            labelCol={{ span:24 }}
                            style={{
                                display:'flex',
                                justifyContent:'center'
                            }}>
                                <GoogleMapWithPinDrop 
                                selectedMarkerPosition={selectedMarkerPosition} 
                                setSelectedMarkerPosition={setSelectedMarkerPosition}
                                />
                            </Form.Item>

                            <Form.Item
                            style={{
                                display:'flex',
                                justifyContent:'center'
                            }}>
                                <Button type='primary' htmlType='submit'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </Carousel>

                    <Row justify={'space-between'}>
                        <Button onClick={handleClickPrev} disabled={content == 1}>Previous</Button>
                        <Button onClick={handleClickNext} disabled={content == 2 || disableNextButton || values.growerId == null || values.growerId == 0}>Next</Button>
                    </Row>

                </Form>
            </Spin>
        </Modal>
    )
}

export default RegisterProductModal;