import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import SideNav from 'src/sideNav/SideNav';
import UnexpectedError from 'src/pages/UnexpectedError';
import { GlobalBreadcrumb } from 'src/pages/shared/GlobalBreadcrumb';
import { Layout, Button, Row, Col, Select, notification, Menu, Dropdown, Typography, Modal, Space } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, SettingOutlined, LoginOutlined, LogoutOutlined, BarsOutlined, InfoCircleOutlined, BellOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { RouteComponentProps, Link, NavLink, useHistory, useParams } from 'react-router-dom';
import UserSecurity from 'src/models/UserSecurity';
import CurrentUser from 'src/utils/CurrentUser';
import Routes from 'src/consts/Routes';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import RoleUtil from 'src/utils/RoleUtil';
import Role from 'src/consts/Role';
import { GrowerSlim, UserSessionActions } from 'src/redux/actions/UserSessionActions';
import { connect } from 'react-redux';
import HistoryUtil from 'src/utils/HistoryUtil';
import UserAlertSettings, { UserAlertSettingsModal } from 'src/pages/users/UserAlertSettings';
import { AlertsHistoryTable } from 'src/pages/dashboard/BinStatusPage/AlertsHistoryTable';

// @ts-ignore
import logoWithText from "src/statics/Shivvers  STIG - Liberator font (1).png";
import { useEffect, useState } from 'react';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { HistoryState } from 'src/pages/dashboard/BinStatusPage/BinStatsPage';
import { useGetBinByIDQuery } from 'src/queries/useGetBinByIDQuery';
import { strictParseInt } from 'src/pages/dashboard/BinStatusPage/BinStatsPageParent';
import RegisterProductModal from 'src/components/RegisterProductModal';
const { Header, Sider, Content } = Layout;
const { Text } = Typography;

interface FullScreenLayoutProps {
    portalAccess: boolean;
    growerChangeAction: (grower: GrowerSlim) => void;
    logoutAction: () => void;
    userId: number;
    // hack
    growerID: number | null,
    growerName: string | null,
}
const changeAlertSettingsFormId = "alert-settings-form-id";
interface FullScreenLayoutState {
    collapsed: boolean;
    user: UserSecurity | null;
    currentGrower: [number, string] | undefined;
    growerIdList: [number, string][];
    isAlertSettingsModalOpen: boolean;
    isAlertHistoryModalOpen: boolean;
}

const FullscreenLayout = (props: React.PropsWithChildren<FullScreenLayoutProps>) => {
    const [collapsed, setCollapsed] = useState(true);
    const [user, setUser] = useState(CurrentUser.Get());
    const currentGrowerId = props.growerID;

    const [growerIdList, setGrowerIdList] = useState<Array<GrowerSlim>>([]);
    const [isAlertSettingsModalOpen, setIsAlertSettingsModalOpen] = useState(false);
    const [isAlertHistoryModalOpen, setIsAlertHistoryModalOpen] = useState(false);
    const [registerProductModalOpen, setRegisterProductModalOpen] = useState(false);

    const params = useParams<any>();
    const binIdParam = params.binId;

    const binId = strictParseInt(binIdParam, 10);
    // Nan, Ids < 0, non-numeric, all evaluate to false
    const validbinIdInUrl = binId >= 0;

    const binInfoQuery = useGetBinByIDQuery(binId, {enabled: validbinIdInUrl, refetchOnReconnect: false, refetchOnWindowFocus: false});

    const openAlertSettingsModal = () => {
        setIsAlertSettingsModalOpen(true);
    }

    const closeAlertSettingsModal = () => {
        setIsAlertSettingsModalOpen(false);
    }

    const openAlertHistoryModal = () => {
        setIsAlertHistoryModalOpen(true);
    }

    const closeAlertHistoryModal = () => {
        setIsAlertHistoryModalOpen(false);
    }

    const handleOpenRegisterProductModal = () =>{
        setRegisterProductModalOpen(true);
    }

    const handleCloseRegisterProductModal = () =>{
        setRegisterProductModalOpen(false);
    }

    const canRegisterProduct = RoleUtil.CanSeeRegisterProductButton();
    
    const menu = (
        <Menu>
          {user?.externalId == null && (
            <Menu.Item>
              <Link to={{ pathname: Routes.generate(Routes.PROFILE) }}>
                <BarsOutlined /> Profile
              </Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <a
              target="_blank"
              href="https://drive.google.com/drive/folders/1r4qdLzNIE4-o2IAowSckN8vh67N59Pt2?usp=drive_link"
              rel="noopener noreferrer"
            >
              <InfoCircleOutlined /> User Manuals
            </a>
          </Menu.Item>
            <Menu.Item>
                <Link to={{}} onClick={handleOpenRegisterProductModal}>
                    <PlusCircleOutlined /> Register a product
                </Link>
            </Menu.Item>
          <Menu.Item>
            <div onClick={() => props.logoutAction()}>
              <LogoutOutlined /> Logout
            </div>
          </Menu.Item>
        </Menu>
      );

    const alertMenu = (
        <Menu>
            <Menu.Item>
                <div onClick={openAlertSettingsModal}>
                    <SettingOutlined /> Alert Settings
                </div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={openAlertHistoryModal}>
                    <InfoCircleOutlined /> Alert History
                </div>
            </Menu.Item>
        </Menu>
    );

    const toggle = () => {
        setCollapsed(!collapsed);
    }

    useEffect(() => {
        if (validbinIdInUrl && binInfoQuery.isInitialLoading) {
            return;
        }
        getData();
    }, [user, binInfoQuery.isInitialLoading, validbinIdInUrl]);

    const getData = () => {
        if (user?.userId) {
            if (RoleUtil.CanViewBinFleet()) {
                EnterpriseApiService.getGrowerIDs(true, true).then((growerIDs) => {
                    //let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
                    let GrowerNameIDPairs: GrowerSlim[] = growerIDs.map((grower) => {
                        const growerSlim: GrowerSlim = {
                            growerId: grower.growerID,
                            externalId: grower.externalId,
                            isExternal: grower.isExternal,
                            growerName: grower.growerName as string,
                        }
                        return growerSlim;
                    })

                    const growerNameAlreadySet = !!props.growerName;
                    if (!growerNameAlreadySet) {
                        props.growerChangeAction(GrowerNameIDPairs[0]);
                    }
                    setGrowerIdList(GrowerNameIDPairs);
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            } else {
                EnterpriseApiService.getGrowersOfUserByUsername(user.userName!).then((growerIDs) => {
                    //let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);

                    let GrowerNameIDPairs: GrowerSlim[] = growerIDs.map((grower) => {
                        const growerSlim: GrowerSlim = {
                            growerId: grower.growerID,
                            externalId: grower.externalId,
                            isExternal: grower.isExternal,
                            growerName: grower.growerName as string,
                        }
                        return growerSlim;
                    });
                    const growerNameAlreadySet = !!props.growerName;
                    if (!growerNameAlreadySet) {
                        props.growerChangeAction(GrowerNameIDPairs[0]);
                    }
                    setGrowerIdList(GrowerNameIDPairs);
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            }
        }
    }

    const isAdmin = RoleUtil.currentUserIsAdmin();
    const showSideNav = RoleUtil.currentUserHasAnyOfRoles(RoleUtil.NearAdminRoles);

    return (
        <Layout className="layout-fullscreen">
            {showSideNav &&
                <Sider
                    trigger={null}
                    collapsible={true}
                    collapsed={collapsed}
                    theme="light"
                    width={195}
                    collapsedWidth={70}
                    breakpoint="lg"
                    onBreakpoint={(broken) => {
                        if (!collapsed) {
                            setCollapsed(broken); }
                        }}
                >
                    <SideNav collapsed={collapsed} />
                </Sider>
            }
            <Layout>
                <Header className={isAdmin ? 'app-header' : 'nonadmin'}>
                    <Row justify="space-between">
                        <Col>
                            <Space direction='horizontal'>
                                <div className="logo-container">
                                    <NavLink to={Routes.generate(Routes.HOME_ROUTE)}>
                                        <img src={logoWithText} className="App-logo" alt="logo" />
                                    </NavLink>
                                </div>
                                {isAdmin && <>
                                    {collapsed ?
                                        <MenuUnfoldOutlined className="trigger" onClick={toggle} /> :
                                        <MenuFoldOutlined className="trigger" onClick={toggle} />}
                                </>
                                }
                            </Space>
                        </Col>
                        <Col>
                            <Row>
                                {/* {growerIdList.length > 0 &&
                                    <Col>
                                        {growerIdList.length > 1 ?
                                            <Select showSearch optionFilterProp='children' key="select" style={{ width: 300, marginRight: '16px' }} labelInValue={true}
                                                value={currentGrower?.growerName ?
                                                    { value: currentGrower?.growerName, label: currentGrowerName } : null}
                                                onChange={(val) => {
                                                    const grower = growerIdList.find(grower => grower.growerName == val.value);
                                                    props.growerChangeAction(grower!);
                                                    HistoryUtil.push(Routes.HOME_ROUTE);
                                                }}>
                                                {growerIdList.map((grower) => {
                                                    return <Select.Option key={`${grower.growerName}-${grower.growerId}-${grower.externalId}`} value={grower.growerName}>{grower.growerName}</Select.Option>;
                                                })}
                                            </Select>
                                            :
                                            <span style={{
                                                borderRadius: '3px', marginRight: '16px', background: 'rgba(150, 150, 150, 0.1)',
                                                border: '1px solid rgba(100, 100, 100, 0.2)', padding: '7px'
                                            }}>
                                                <Text>{growerIdList[0].growerName}</Text>
                                            </span>
                                        }
                                    </Col>
                                } */}

                                {growerIdList.length > 0 &&
                                    <Col>
                                        {growerIdList.length > 1 ? null
                                            :
                                            <span style={{
                                                borderRadius: '3px', marginRight: '16px', background: 'rgba(150, 150, 150, 0.1)',
                                                border: '1px solid rgba(100, 100, 100, 0.2)', padding: '7px'
                                            }}>
                                                <Text>{growerIdList[0].growerName}</Text>
                                            </span>
                                        }
                                    </Col>
                                }

                                <Col xs={user?.externalId == null ? undefined : 0 }>
                                    <Dropdown overlay={alertMenu}>
                                        <Button type="primary" icon={<BellOutlined />} style={{ marginRight: '16px' }}> Alerts </Button>
                                    </Dropdown>
                                </Col>
                                <Col>
                                    {user ?
                                        // the menu flickers when the user profile button is smaller than the dropdown and on the edge of the screen
                                        // 3-6 chars or less
                                        // https://github.com/ant-design/ant-design/issues/50572
                                        // bottomRight or bottomLeft seems to work.  bottom or bottomCenter cause the flicker as of 5.21.2
                                        <Dropdown overlay={menu} placement="bottomRight">
                                            <Button type="primary" icon={<UserOutlined />} style={{ marginRight: '16px' }}>
                                                {user.userName}
                                            </Button>
                                        </Dropdown>
                                        :
                                        <Link to={Routes.generate(Routes.LOGIN)}>
                                            <Button type="primary" icon={<LoginOutlined />} style={{ marginRight: '16px' }}>
                                                Login
                                            </Button>
                                        </Link>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <GlobalBreadcrumb
                        separator={<b> / </b>}
                        finalItem={'b'}
                        finalProps={{
                            style: { color: 'red' }
                        }}
                    />
                </Header>
                <Content id="app-main" className={isAdmin ? 'page-content' : 'page-content-nonadmin'}>
                    <Scrollbars autoHide={true} className="page-scrollbar">
                        <UnexpectedError>
                            {props.children}
                        </UnexpectedError>
                    </Scrollbars>
                </Content>
            </Layout>

            <UserAlertSettingsModal userId={props.userId} open={isAlertSettingsModalOpen} requestToClose={closeAlertSettingsModal} />

            <RegisterProductModal 
            modalOpen={registerProductModalOpen} 
            closeModal={handleCloseRegisterProductModal}
            growerId={props.growerID ?? 0}            
            />

            <Modal
            centered={true}
            footer={null}
            closable={true}
            bodyStyle={{ padding: 16 }}
            width={'80%'}
            open={isAlertHistoryModalOpen}
            onCancel={closeAlertHistoryModal}
            destroyOnClose={true}
            >
                <AlertsHistoryTable growerId={currentGrowerId ?? undefined} />
            </Modal>
        </Layout>
    );
}

function mapDispatchToProps(dispatch: any) {
    return {
        growerChangeAction: (grower: GrowerSlim) => dispatch(UserSessionActions.changeGrower(grower)),
        logoutAction: () => dispatch(UserSessionActions.logout())
    };
}

function mapStateToProps(state: StateStoreModel) {
    return {
        growerID: state.UserSession.GrowerID,
        growerName: state.UserSession.GrowerName,
        grower: state.UserSession.grower,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenLayout);
