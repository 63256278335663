import React, {useReducer, useRef} from 'react';
import {useMap} from '@vis.gl/react-google-maps';

import reducer, {
  useDrawingManagerEvents,
  useOverlaySnapshots
} from './UndoRedo';

import { DrawingActionKind } from './types';

interface UndoAllControlProps {
  map: google.maps.Map | null;
  drawingManager: google.maps.drawing.DrawingManager | null;
  setSelectedMarkerPosition: () => void;
}

export const UndoAllControl : React.FC<UndoAllControlProps> = ({ map, drawingManager, setSelectedMarkerPosition }) => {

    const [state, dispatch] = useReducer(reducer, {
        past: [],
        now: [],
        future: []
      });

    const overlaysShouldUpdateRef = useRef<boolean>(false);  
    useDrawingManagerEvents(drawingManager, overlaysShouldUpdateRef, dispatch);
    useOverlaySnapshots(map, state, overlaysShouldUpdateRef);
    
    return(
        <>
        <div className='drawin-history'>
            <button
            onClick={() => {
                dispatch({ type: DrawingActionKind.RESET });
                drawingManager?.setOptions({
                    drawingMode: google.maps.drawing.OverlayType.MARKER,
                    drawingControlOptions:{
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            google.maps.drawing.OverlayType.MARKER,
                        ]
                    }
                })
                setSelectedMarkerPosition();
            }}
            disabled={!state.past.length}>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24">
                <path d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z" />
                </svg>
            </button>
        </div>
        </>
    )
}