import { useMap } from "@vis.gl/react-google-maps";
import { useEffect } from "react";

export interface MapHandlerProps {
  place: google.maps.places.PlaceResult | null;
  map: google.maps.Map | null;
}

export const MapHandler: React.FC<MapHandlerProps> = ({ map, place }) => {

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
};
