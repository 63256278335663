import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import React from "react";
import { Dispatch, SetStateAction } from "react";
import { useDrawingManager } from "./useDrawingManager";
import { UndoAllControl } from "./UndoAllControl";
import { LatLng } from "./types";



interface MapControlWithDrawingProps{
    map: google.maps.Map | null;
    setSelectedMarkerPosition: (coords: LatLng | null) => void;
  }

  
  const MapControlWithDrawing : React.FC<MapControlWithDrawingProps> = ({map, setSelectedMarkerPosition}) => {

    const addListenerCallback = (newDrawingManager: google.maps.drawing.DrawingManager) => { 
        newDrawingManager.addListener('overlaycomplete', (event: any) => {
        if(event.type === 'marker'){
            var position = event.overlay.getPosition();
            position = event.overlay.getPosition();
            const lat = position.lat(); 
            const lng = position.lng(); 
            setSelectedMarkerPosition({lat, lng});
  
            newDrawingManager?.setOptions({
                drawingMode: null,
                drawingControlOptions:{
                  position: google.maps.ControlPosition.TOP_RIGHT,
                  drawingModes: []
                }
            })
        }
      })
    }
  
    const drawingManager = useDrawingManager(map, null, addListenerCallback);
  
    return(
        <MapControl position={ControlPosition.TOP_RIGHT}>
            <UndoAllControl map={map} drawingManager={drawingManager} setSelectedMarkerPosition={() => setSelectedMarkerPosition(null)}/>
        </MapControl>
    )
  }
  
  export default MapControlWithDrawing;