import * as React from 'react';
import Dashboard from 'src/pages/dashboard/HomeScreen/Dashboard';
import Admin from 'src/pages/admin/Admin';
import Login from 'src/pages/login/Login';
import PublicLayout from 'src/layouts/PublicLayout';
// import Reports from 'src/pages/reports/Reports';
import Users from 'src/pages/users/Users';
import RequestResetPassword from 'src/pages/login/RequestResetPassword';
import ResetPassword from 'src/pages/login/ResetPassword';
import Routes from 'src/consts/Routes';
import RouteWithLayout from 'src/pages/shared/RouteWithLayout';
import withAuthorization from 'src/pages/shared/WithAuthorization';
import { connect } from 'react-redux';
import { ConfigProvider, Modal, Spin} from 'antd';
import {
    Switch,
    useHistory,
    useLocation,
    } from 'react-router-dom';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { UserSessionActions } from 'src/redux/actions/UserSessionActions';
import { VersionActions } from 'src/redux/actions/VersionActions';
import './App.less';
import MoreSiteDetail from 'src/pages/dashboard/SiteDetailsScreen/MoreSiteDetail';
import BinTestPage from 'src/pages/dashboard/BinStatusPage/BinTestPage';
import Role from 'src/consts/Role';
import Profile from 'src/pages/Profile';
import Settings from 'src/pages/settings/settings';
import NotFoundComponent from 'src/pages/NotFoundComponent';
import { BinCommander } from 'src/pages/binOverview/BinCommander';
import BinStatsPageParent from 'src/pages/dashboard/BinStatusPage/BinStatsPageParent';
import { useEffect, useState } from 'react';
import {createSignalRContext} from 'react-signalr';
import CurrentUser from 'src/utils/CurrentUser';
import { deviceQueryKeys } from 'src/components/BinDetails';
import { useQueryClient } from '@tanstack/react-query';
import { LiveStreaming } from 'src/pages/dashboard/Camera/LiveStreaming';
import { PRIMARY_BLUE_SHIVVERS } from './theme';
import RoleUtil from 'src/utils/RoleUtil';
import BinStatsShivversPageParent from 'src/pages/dashboard/BinStatusPage/BinStatsShivversPageParent';
import { ExternalUserEditContainer } from 'src/pages/users/ExternalUserEdit';

export const SignalRContext = createSignalRContext({
    //shareConnectionBetweenTab: true,
});
const {Provider: SignalRProvider} = SignalRContext;

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

const dashboard = withAuthorization()(Dashboard);
const users = withAuthorization(RoleUtil.NearAdminRoles)(Users);
const admin = withAuthorization(RoleUtil.AdminRoles)(Admin);
const BinCommanderAdmin = withAuthorization(RoleUtil.NearAdminRoles)(BinCommander);
// const reports = withAuthorization()(Reports);

interface AppComponentProps {
    userSessionChecking: boolean;
    versionOutdated: boolean;
    versionResetAction: () => void;
    userSessionCheckAction: () => void;
}

const App = (props: AppComponentProps) => {

    const userSecurity = CurrentUser.Get();

    const [showDevtools, setShowDevtools] = useState(false);
    useEffect(() => {
        // @ts-ignore
        window.toggleDevtools = () => setShowDevtools((old) => !old)
      }, []);

    const location = useLocation();
    const history = useHistory();
    const currentPath = location.pathname;

    useEffect(() => {
        history.listen((location, action) => {
            if (DEBUG) {
                console.groupCollapsed('Route Change');
                console.info('Location', location);
                console.log('Action:', action);
                console.groupEnd();
            }
    
            let currentPathTokens = currentPath ? currentPath.split('/').filter(s => !!s) : [];
            let nextPathTokens = location.pathname ? location.pathname.split('/').filter(s => !!s) : [];
            if (currentPathTokens.length > 0 && nextPathTokens.length > 0 && currentPathTokens[0] !== nextPathTokens[0]) {
                // @ts-ignore
                document.getElementById('app-main')?.firstChild?.firstChild?.scrollTo(0, 0); // change back
            }
    
        });    
    }, []);

    useEffect(() => {
        props.userSessionCheckAction();
    }, []);

    const { versionOutdated } = props;
    useEffect(() => {
        if (versionOutdated) {
            Modal.confirm({
                title: 'Out of date',
                content: 'A new version of the app was found. Do you want to update?',
                onOk: () => {
                    props.versionResetAction();
                    // location.reload(true);
                    history.go(0);
                },
                onCancel: () => { props.versionResetAction(); },
            });
        }
    }, [versionOutdated]);

    if (props.userSessionChecking) {
        return (
            <div className="app-spinner" >
                <Spin tip="Loading..." size="large" />
            </div>
        );
    } else {
        return <React.Fragment>
            <SignalRProvider
            url="/signalr"
            automaticReconnect={true}
            connectEnabled={true}
            // onOpen={(connection) => {
            //     console.log("signalr connected: ", connection);
            // }}
            //connectEnabled={userSecurity?.userId != null}
            dependencies={[userSecurity?.userId]}
            >
            <ConfigProvider theme={{token: {
            //    colorPrimary: "#CCBE4A"
            //colorPrimary: "#4B8077",
            //colorPrimary: "#228B22"
            colorPrimary: PRIMARY_BLUE_SHIVVERS,
            },
                components: {
                }
                }}>
            <Switch>
                <RouteWithLayout exact={true} path={Routes.HOME_ROUTE} component={dashboard} />
                <RouteWithLayout path={Routes.DASHBOARD} component={dashboard} />
                <RouteWithLayout path={Routes.ADMIN + '/1'} component={admin} />
                <RouteWithLayout path={Routes.ADMIN + '/2'} component={admin} />
                <RouteWithLayout path={Routes.ADMIN + '/3'} component={admin} />
                <RouteWithLayout path={Routes.ADMIN} component={admin} />
                <RouteWithLayout path={Routes.USERS_INTERNAL} component={users} />
                <RouteWithLayout path={Routes.USERS_MYSHIVVERS} component={users} />
                <RouteWithLayout path={Routes.USERS_BASE} component={users} />
                <RouteWithLayout path={Routes.LOGIN} component={Login} layout={PublicLayout} />
                <RouteWithLayout path={Routes.PROFILE} component={Profile} />
                <RouteWithLayout path={Routes.SETTINGS} component={Settings} />
                <RouteWithLayout path={Routes.REQUEST_RESET_PASSWORD} component={RequestResetPassword} layout={PublicLayout} />
                <RouteWithLayout path={Routes.RESET_PASSWORD_LINK} component={ResetPassword} layout={PublicLayout} />
                {/* <RouteWithLayout path={Routes.REPORTS_BASE} component={reports} /> */}
                <RouteWithLayout path={Routes.SITE_DETAIL} component={MoreSiteDetail} />
                <RouteWithLayout path={`${Routes.BIN_STATS}/:binId`} component={BinStatsPageParent} />
                <RouteWithLayout path={`${Routes.BIN_STATS_SHIVVERS}/:shivversBinId`} component={BinStatsShivversPageParent} />
                <RouteWithLayout path={Routes.BINVISUAL_TEST_PAGE} component={BinTestPage} />
                <RouteWithLayout path={Routes.BIN_OVERVIEW} component={BinCommanderAdmin} />
                <RouteWithLayout path={"/livestream"} component={LiveStreaming} />
                <RouteWithLayout path={Routes.SHIVVERS_USER_ADD} component={ExternalUserEditContainer} />

                {/* This needs to be the last item */}
                <RouteWithLayout component={NotFoundComponent} layout={PublicLayout} />
            </Switch>
            {showDevtools && (
                <React.Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction />
                </React.Suspense>
            )}
            </ConfigProvider>
            <Worker />
        </SignalRProvider>
        </React.Fragment>
    }
};

function mapDispatchToProps(dispatch: any) {
    return {
        userSessionCheckAction: () => dispatch(UserSessionActions.check()),
        versionResetAction: () => dispatch(VersionActions.reset()),
    };
}

function mapStateToProps(state: StateStoreModel) {
    return {
        userSessionChecking: state.UserSession.Checking,
        versionOutdated: state.Version.Outdated
    };
}

const Worker = () => {

    const queryClient = useQueryClient();

    SignalRContext.useSignalREffect("NewSnapshot", (...args) => {

        try {
            console.log("signalr: A bin has updated data", {...args?.[0]});
            const deviceId = args?.[0]?.DeviceId;
            queryClient.invalidateQueries([...deviceQueryKeys.stateFromAzure(deviceId)]);
        } catch (error) {
            console.error("signalr error: ", error);
        }

    }, [queryClient]);

    return <></>;
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
