import { Line} from 'react-chartjs-2';
import React, { useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { binDBKeys } from 'src/pages/binOverview/BinCommander';
import { useBinStateFromAzure } from 'src/queries/useBinStateFromAzure';
import { getUserTimezoneOffset } from '../../../components/BinDetails';
import { DatePicker, Divider, Skeleton } from 'antd';
import BinApiService from 'src/api/BinApiService';
import { BaseChartProps } from './TemperatureCableChart';
import { queryClient } from 'src';
import { ChartDatePicker } from './Co2Chart';
import { RangeValue } from './ChartContainer';
import { tooltipItemRound, useFanHeaterChangeHistory } from './shared';
import { ChartDataset, Chart as ChartJS} from 'chart.js';
// @ts-ignore
import crosshair from '@adelamar/chartjs-plugin-crosshair';
import { useModeChangeHistory } from './LayerMCHistoryChart';
import annotationPlugin from 'chartjs-plugin-annotation';
import {annotationFixOverlapPlugin} from 'src/pages/dashboard/charts/plugins/annotationOverlapFixPlugin';
ChartJS.register(crosshair);
ChartJS.register(annotationPlugin);
ChartJS.register(annotationFixOverlapPlugin);
// @ts-ignore
ChartJS.defaults.plugins.crosshair.zoom = {enabled: false};

ChartJS.defaults.animation = false;
ChartJS.defaults.responsive = true;
ChartJS.defaults.maintainAspectRatio = false;
ChartJS.defaults.datasets.line.pointRadius = 0;

export const ambientFanDatasetColor = "rgb(235,124,52)";
export const plenumFanDatasetColor = "rgb(66,113,192)";


export const plenumDewPointColor = 'rgba(254,106,138)';

export const LayerDewPointChart = (props: BaseChartProps & {showTitle?: boolean, yTitle?: string}) => {

  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');


  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
	// the component is controlled or not, that value comes from its
	// props or from its internal state.

	const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "Ambient_Vs_Plenum", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, retry: 0, keepPreviousData: true, queryFn: async (q) => {
      const results = await BinApiService.getAmbientPlenumHistory(props.binId, value?.[0]?.toISOString()!, value?.[1]?.toISOString()!, null, q.signal);
      queryClient.removeQueries({queryKey: q.queryKey.slice(0, -1), type: "inactive"});

      return results;
    }, enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null
  });

  
  const chartLowestLayerQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "LowestLayer", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, retry: 0, keepPreviousData: true, queryFn: async (q) => {
      const results = await BinApiService.getLowestLayerTemperatureHistory(props.binId, value?.[0]?.toISOString()!, value?.[1]?.toISOString()!, null, q.signal);
      queryClient.removeQueries({queryKey: q.queryKey.slice(0, -1), type: "inactive"});

      return results;
    }, enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);



  return <div style={{width: "100%", height: props.chartHeight ?? "320px", display: "flex", justifyContent: "center" }}>

{chartQuery.data && <Line options={{
        responsive: true,
        animation: false,
        spanGaps: false,
        maintainAspectRatio: false,
        datasets: {
          line: {
            pointRadius: 0 // disable for all `'line'` datasets
          }
        },
        scales: {
          x: {
            type: "time",
            time: {
              minUnit: 'day',
              displayFormats: {
                //day: 'l'
              }
            },
          },
          y: {
            suggestedMin: 32,
            suggestedMax: 80,
            title: {
              font: {
                size: 16,
              },
              display: true,
              text: props.yTitle ?? "Temperature °F",
            }
          }
        },
        plugins: {
          annotation: {
            annotations: [
              ...modeHistory.annotations,
              ...fanHeaterChangeHistory.annotations,
            ],
          },
          legend: {
            position: 'top' as const,
            display: true,
          },
          title: {
            display: props.showTitle ?? true,
            text: 'Incoming Air Dew Point',
          },

          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: tooltipItemRound,
            },
          },
          autocolors: {
            enabled: false,
          },
// @ts-ignore
sync: {
            enabled: true,
            group: 1,
            suppressTooltips: false,   // suppress tooltips when showing a synced tracer
          },
        },
      }} data={{
        // @ts-ignore
        datasets: [
          {
            data: chartQuery.data?.[3]?.data ?? [],
            label: "Plenum Dew Point",
            borderColor: plenumFanDatasetColor,
            backgroundColor: plenumFanDatasetColor,
            
          },  
          {
          //@ts-ignore different DTO types
          data: chartLowestLayerQuery.data?.[0]?.data ?? [],
          label: "Coldest Layer Temp",
          borderColor: plenumDewPointColor,
          backgroundColor: plenumDewPointColor,
          parsing: {
            yAxisKey: "temperatureF",
          }
        },
      ],
      }} />}
      {chartQuery.data == null && <Skeleton loading />}
    </div>
}

export const AmbientPlenumChart = (props: BaseChartProps & {showTitle?: boolean} & {showRecommendation?: boolean, showTemperatureChart?: boolean}) => {
  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');


  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
	// the component is controlled or not, that value comes from its
	// props or from its internal state.

	const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);


  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "Ambient_Vs_Plenum", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, retry: 0, keepPreviousData: true, queryFn: async (q) => {
      const results = await BinApiService.getAmbientPlenumHistory(props.binId, value?.[0]?.toISOString()!, value?.[1]?.toISOString()!, null, q.signal);
      queryClient.removeQueries({queryKey: q.queryKey.slice(0, -1), type: "inactive"});

      return results;
    }, enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null
  });

  const chartQueryEMC = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "Ambient_Vs_Plenum_EMC", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, retry: 0, keepPreviousData: true, queryFn: async (q) => {
      const results = await BinApiService.getAmbientPlenumEMCHistory(props.binId, value?.[0]?.toISOString()!, value?.[1]?.toISOString()!, null, q.signal);
      queryClient.removeQueries({queryKey: q.queryKey.slice(0, -1), type: "inactive"});

      return results;
    }, enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0]!, value?.[1]!);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);



  const datasets:  Array<ChartDataset> = [];
  if (chartQuery.data) {
  datasets.push(
// @ts-ignore
{
        ...chartQuery.data[0],
        label: chartQuery.data[0].label!,
        borderColor: plenumFanDatasetColor,
        backgroundColor: plenumFanDatasetColor,
      });
  if (props.showRecommendation ?? true) {
// @ts-ignore
datasets.push(
// @ts-ignore
{
// @ts-ignore
...chartQuery.data[1],
        label: chartQuery.data[1].label!,
        borderColor: "rgb(165,164,164)",
        backgroundColor: "rgb(165,164,164)",
      }
    );    
  }
datasets.push(
// @ts-ignore
{
    ...chartQuery.data[2],
    label: chartQuery.data[2].label!,
    borderColor: ambientFanDatasetColor,
    backgroundColor: ambientFanDatasetColor,
  }
);
  }
return (<div style={{minHeight: props.containerMinHeight ?? "800px"}}>

    {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
    <div style={{width: "100%", height: props.chartHeight ?? "320px", display: "flex", justifyContent: "center" }}>
      {chartQueryEMC.data && <Line
       options={{
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        spanGaps: false,
        datasets: {
          line: {
            pointRadius: 0 // disable for all `'line'` datasets
          }
        },
        scales: {
          x: {
            type: "time",
            time: {
              minUnit: 'day',
              displayFormats: {
                //day: 'l'
              }
            },
          },
          y: {
            min: props.min ?? 0,
            max: props.max ?? undefined,
            suggestedMax: 24,
            ticks: {
              precision: 0,
              stepSize: 1,
            },
            title: {
              font: {
                size: 16,
              },
              display: true,
              text: (chartQueryEMC.data?.[0]?.unitLabel)!,
            }
          }
        },
        plugins: {
          annotation: {
            annotations: [
              ...modeHistory.annotations,
              ...fanHeaterChangeHistory.annotations,
            ],
          },
          legend: {
            position: 'top' as const,
            display: true,
          },
          title: {
            display: props.showTitle ?? true,
            text: 'Incoming Air EMC',
          },

          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: tooltipItemRound,
            },
          },
          autocolors: {
            enabled: false,
          },
// @ts-ignore
sync: {
            enabled: true,
            group: 1,
            suppressTooltips: false,   // suppress tooltips when showing a synced tracer
          },
        },
      }} data={{
        datasets: [
          {
            ...chartQueryEMC.data[0],
            label: chartQueryEMC.data[0].label!,
            borderColor: "rgb(66,113,192)",
            backgroundColor: "rgb(66,113,192)",
          },
          {
            ...chartQueryEMC.data[1],
            label: chartQueryEMC.data[1].label!,
            borderColor: "rgb(235,124,52)",
            backgroundColor: "rgb(235,124,52)",
          },
        ]
      }} />}
      {chartQueryEMC.data == null && <Skeleton loading />}
    </div>

{(props.showTemperatureChart ?? true) && <>

    <div style={{width: "100%", height: props.chartHeight ?? "320px", display: "flex", justifyContent: "center" }}>

      {chartQuery.data && <Line options={{
        responsive: true,
        animation: false,
        spanGaps: false,
        maintainAspectRatio: false,
        datasets: {
          line: {
            pointRadius: 0 // disable for all `'line'` datasets
          }
        },
        scales: {
          x: {
            type: "time",
            time: {
              minUnit: 'day',
              displayFormats: {
                //day: 'l'
              }
            },
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 80,
            title: {
              font: {
                size: 16,
              },
              display: true,
              //text: "Temperature °F",
              text: (chartQuery.data?.[0]?.unitLabel)!,
            }
          }
        },
        plugins: {
          annotation: {
            annotations: [
              ...modeHistory.annotations,
              ...fanHeaterChangeHistory.annotations,
            ],
          },
          legend: {
            position: 'top' as const,
            display: true,
          },
          title: {
            display: props.showTitle ?? true,
            text: 'Incoming Air Temp',
          },

          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: tooltipItemRound,
            },
          },
          autocolors: {
            enabled: false,
          },
// @ts-ignore
sync: {
            enabled: true,
            group: 1,
            suppressTooltips: false,   // suppress tooltips when showing a synced tracer
          },
        },
      }} data={{
        // @ts-ignore
        datasets: datasets,
      }} />}
      {chartQuery.data == null && <Skeleton loading />}
    </div>
    </>}

  </div>);

};


export const AmbientPlenumEMCChart = (props: BaseChartProps) => {

  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');

  const [value, setValue] = useState<RangeValue>([priorDate, currentDate]);

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    setValue(values);
  }, []);

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "Ambient_Vs_Plenum_EMC", { startDate: value?.[0], endDate: value?.[1] }], staleTime: Infinity, retry: 0, keepPreviousData: true, queryFn: async (q) => {
      const results = await BinApiService.getAmbientPlenumEMCHistory(props.binId, value?.[0]?.toISOString()!, value?.[1]?.toISOString()!, null, q.signal);
      queryClient.removeQueries({queryKey: q.queryKey.slice(0, -1), type: "inactive"});

      return results;
    }, enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null
  });




  return (<div>


    <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange}  />
    <div style={{ display: "flex", justifyContent: "center" }}>
      {chartQuery.data && <Line options={{
        responsive: true,
        animation: false,
        spanGaps: false,
        datasets: {
          line: {
            pointRadius: 0 // disable for all `'line'` datasets
          }
        },
        scales: {
          x: {
            type: "time",
            time: {
              minUnit: 'day',
              displayFormats: {
                //day: 'l'
              }
            },
          },
          y: {
            min: 0,
            suggestedMax: 40,
            ticks: {
              precision: 0,
              stepSize: 1,
            },
            title: {
              font: {
                size: 16,
              },
              display: true,
              text: (chartQuery.data?.[0]?.unitLabel)!,
            }
          }
        },
        plugins: {
          legend: {
            position: 'top' as const,
            display: true,
          },
          title: {
            display: true,
            text: 'Incoming Air EMC',
          },

          tooltip: {
            mode: 'index',
            intersect: false
          },
          autocolors: {
            enabled: false,
          },
// @ts-ignore
          sync: {
            enabled: true,
            group: 1,
          },
        },
      }} data={{
        datasets: [
          {
            ...chartQuery.data[0],
            label: chartQuery.data[0].label!,
            borderColor: "rgb(66,113,192)",
            backgroundColor: "rgb(66,113,192)",
          },
          {
            ...chartQuery.data[1],
            label: chartQuery.data[1].label!,
            borderColor: "rgb(235,124,52)",
            backgroundColor: "rgb(235,124,52)",
          },
        ]
      }} />}
      {chartQuery.data == null && <Skeleton loading />}
    </div>

  </div>);

};