import Role from 'src/consts/Role';
import UserSecurityDTO from 'src/models/UserSecurityDTO';
import CurrentUser from './CurrentUser';
export default class RoleUtil {
    
    public static currentUserName(): string{

        return CurrentUser.Get()?.userName ?? "N/A";
    }

    public static currentUserisAtLeastPowerUser(): boolean {
        const roles = [Role.ADMIN, Role.SUPERADMIN, Role.POWERUSER, Role.SUPPORT];
        const atLeastPowerUser = RoleUtil.currentUserHasAnyOfRoles(roles);
        return atLeastPowerUser;

    }
    public static currentUserIsAdmin(): boolean {
        const roles = [Role.ADMIN, Role.SUPERADMIN];
        const isAdmin = RoleUtil.currentUserHasAnyOfRoles(roles);
        return isAdmin;
    }

    public static currentUserIsDealer(): boolean {
        const roles = [Role.DEALER];
        return RoleUtil.currentUserHasAnyOfRoles(roles);
    }

    public static NearAdminRoles = [Role.ADMIN, Role.DEALER, Role.DSM, Role.HEMPDSM, Role.SUPERADMIN];
    public static AdminRoles = [Role.ADMIN, Role.SUPERADMIN];

    public static CanViewBinFleet(): boolean {
        return RoleUtil.currentUserHasAnyOfRoles(RoleUtil.NearAdminRoles);
    }

    public static CanModifyControls(): boolean {
        return CurrentUser.Get()?.canModifyControls() ?? false;
    }
    public static IsViewOnly() {
        return CurrentUser.Get()?.canViewControls() ?? false;
    }

    public static CanViewUserList(): boolean {
        return RoleUtil.currentUserHasAnyOfRoles(RoleUtil.NearAdminRoles);
    }

    public static CanViewSidenav(): boolean {
        return RoleUtil.currentUserHasAnyOfRoles(RoleUtil.NearAdminRoles);
    }

    public static CanSeeRegisterProductButton(): boolean {
        return !RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN])
    }


    public static currentUserHasAnyOfRoles(roles: UserSecurityDTO['roles']): boolean {
        let currentUser = CurrentUser.Get();

        if (currentUser) {
            for (let role of roles) {
                if (currentUser.isInRole(role)) {
                    return true;
                }
            }
        }

        return false;
    }
}
