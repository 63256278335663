import * as React from 'react';
import UnexpectedError from 'src/pages/UnexpectedError';
import UserSecurity from 'src/models/UserSecurity';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
// @ts-ignore
import logoWithText from "src/statics/Shivvers  STIG - Liberator font (1).png";
import { NavLink } from 'react-router-dom';
import Routes from 'src/consts/Routes';


const { Header, Content } = Layout;

interface MainLayoutProps {
    user: UserSecurity | null;
}

class PublicLayout extends React.Component<MainLayoutProps, {}>  {
    constructor(props: MainLayoutProps) {
        super(props);
    }

    render() {
        return (
            <Layout>
                <Header className="app-header public">
                <div className="logo-container">
                    <NavLink to={Routes.generate(Routes.HOME_ROUTE)}>
                        <img src={logoWithText} className="App-logo" alt="logo" />
                    </NavLink>
                </div>
                </Header>
                <Content id="app-main">
                    <UnexpectedError>
                        {this.props.children}
                    </UnexpectedError>
                </Content>
            </Layout>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
        return {
            // logoutAction: () => dispatch(UserSessionActions.logout())
        };
    }

function mapStateToProps(state: StateStoreModel) {
        return {
            user: state.UserSession.Value
        };
    }

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);
