import { Layout, Tabs, TabsProps } from "antd";
import React, { useState } from "react";
import ControlPanel from "./ControlPanel";

const PremierPlusDashboard = () => {

    const [activeTabKey, setActiveTabKey] = useState("1");
    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: 'Dashboard',
          children: <ControlPanel />
        }
      ];
      
    return (
        <Layout.Content className="dashboard">
            <Tabs activeKey={activeTabKey} items={items} onChange={handleTabChange}/>
        </Layout.Content>
    );
};

export default PremierPlusDashboard;
