import { useQuery } from "@tanstack/react-query";
import BinApiService from "src/api/BinApiService";
import { deviceQueryKeys, getUserTimezoneOffset } from "src/components/BinDetails";

export const useBinStateFromAzure = (params: {
    azureDeviceID: string | null | undefined, binId: number, userTimeZoneOffset?: number
},
    queryOptions: {
        enabled?: boolean,
        staleTime?: number,
        refetchInterval?: number,
    }
) => {

    return useQuery(deviceQueryKeys.stateFromAzure(params.azureDeviceID!), async (q) => {
        if (params.azureDeviceID == null) {
            throw new Error("No Azure device supplied");
        }
        const binDTO = await BinApiService.getBinDetailFromAzure(params.azureDeviceID!, params.binId, params.userTimeZoneOffset ?? getUserTimezoneOffset(), q.signal);
        if (binDTO == null) {
            throw new Error("Bin State from device is empty");
        }
        return binDTO;
    }, {
        enabled: queryOptions.enabled,
        staleTime: queryOptions.refetchInterval,
        refetchInterval: queryOptions.refetchInterval,
        onError: (err) => console.error("problem getting binState from Azure", err),
    });
};