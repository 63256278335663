// Service TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
import BaseApi from './BaseApi';
import BinSettingsDTO from '../models/BinSettingsDTO';
import SecretDTO from '../models/SecretDTO';
import SyncBatchDTO from '../models/SyncBatchDTO';
import WeatherMonitorChangeSettingsDTO from '../models/WeatherMonitorChangeSettingsDTO';
import DesiredPropertiesDTO from '../models/DesiredPropertiesDTO';
import PowerCycleBoardDTO from '../models/PowerCycleBoardDTO';
import SetIgnoredSolenoidsRequestDTO from '../models/SetIgnoredSolenoidsRequestDTO';
import SetIgnoredSensorRequestDTO from '../models/SetIgnoredSensorRequestDTO';
import ValvePositionDTO from '../models/ValvePositionDTO';
import UnloadDTO from '../models/UnloadDTO';
import LoadDTO from '../models/LoadDTO';
import ReportedWeatherMonitorSettingsDTO from '../models/ReportedWeatherMonitorSettingsDTO';
import SetGrainTypeRquestDTO from '../models/SetGrainTypeRquestDTO';
import ChangeHeaterModeDTO from '../models/ChangeHeaterModeDTO';
import HeaterModeOptionsRequestDTO from '../models/HeaterModeOptionsRequestDTO';
import FanOnAllowedTimeRequestDTO from '../models/FanOnAllowedTimeRequestDTO';
import FanOnAllowedTimePerWeekRequestDTO from '../models/FanOnAllowedTimePerWeekRequestDTO';
import UpdateMaxLayerGrainTempRequestDTO from '../models/UpdateMaxLayerGrainTempRequestDTO';
import LayerPercentageDTO from '../models/LayerPercentageDTO';
import DesiredRequestSettingsDTO from '../models/DesiredRequestSettingsDTO';
import AlertHistoryRequestDTO from '../models/AlertHistoryRequestDTO';
import ConfigureBasicSettingsRequestDTO from '../models/ConfigureBasicSettingsRequestDTO';
import RegisterProductDTO from '../models/RegisterProductDTO';
import ConfigureSystemRequestDTO from '../models/ConfigureSystemRequestDTO';
import BatchDTO from '../models/BatchDTO';
import GeneralDTO from '../models/GeneralDTO';
import ECDeviceDTO from '../models/ECDeviceDTO';
import BinAssemblyDTO from '../models/BinAssemblyDTO';
import ErrorListDTO from '../models/ErrorListDTO';
import BinInfoDTO from '../models/BinInfoDTO';
import BinDTO from '../models/BinDTO';
import ResponseResultDTO from '../models/ResponseResultDTO';
import SnapshotDTO from '../models/SnapshotDTO';
import CustomRoutineNamesDTO from '../models/CustomRoutineNamesDTO';
import UploadResultDTO from '../models/UploadResultDTO';
import PingAndBinDTO from '../models/PingAndBinDTO';
import DristackmoduleLogsDTO from '../models/DristackmoduleLogsDTO';
import MoistureContentSnapshotDTO from '../models/MoistureContentSnapshotDTO';
import OfflineBinReportDTO from '../models/OfflineBinReportDTO';
import HourlyDTO from '../models/HourlyDTO';
import MoistureForecastDTO from '../models/MoistureForecastDTO';
import ResultCameraDTO from '../models/ResultCameraDTO';
import ResponseCameraImageDTO from '../models/ResponseCameraImageDTO';
import LiveStreamForDeviceDTO from '../models/LiveStreamForDeviceDTO';
import CellStrengthDatasetDTO from '../models/CellStrengthDatasetDTO';
import OpiCableDatasetDTO from '../models/OpiCableDatasetDTO';
import BinSenseCableDatasetDTO from '../models/BinSenseCableDatasetDTO';
import AlertHistoryResponseDTO from '../models/AlertHistoryResponseDTO';
import FanHeaterHistoryChartResponseDTO from '../models/FanHeaterHistoryChartResponseDTO';
import LayerSummaryDatasetDTO from '../models/LayerSummaryDatasetDTO';
import DatasetDoubleDTO from '../models/DatasetDoubleDTO';
import CO2DatasetDTO from '../models/CO2DatasetDTO';
import MoistureRemovalDatapointDTO from '../models/MoistureRemovalDatapointDTO';
import ModeChangeHistoryDatasetDTO from '../models/ModeChangeHistoryDatasetDTO';
import FanHeaterChangeTimeDatapointDTO from '../models/FanHeaterChangeTimeDatapointDTO';
import TargetLayerDatasetDTO from '../models/TargetLayerDatasetDTO';
import RangeFinderDatapointDTO from '../models/RangeFinderDatapointDTO';
import UserLoadDatapointDTO from '../models/UserLoadDatapointDTO';
import CalculateGrainHeightResponseDTO from '../models/CalculateGrainHeightResponseDTO';
import PremierPlusBinDTO from '../models/PremierPlusBinDTO';

export class BinApiService extends BaseApi {

    // post: api/grainbin/SetBinSettingsOverrides?deviceName=${encodeURIComponent(deviceName)}
    public setBinSettingsOverrides(deviceName: string, binSettings: BinSettingsDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/SetBinSettingsOverrides`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(binSettings, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/StopCustomRoutine?binId=${binId}
    public stopCustomRoutine(binId: number, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/StopCustomRoutine`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/UploadCustomRoutine?binId=${binId}
    public uploadCustomRoutine(binId: number, file: File, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/UploadCustomRoutine`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(file, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/DeleteCustomRoutine?binId=${binId}&routineName=${encodeURIComponent(routineName)}
    public deleteCustomRoutine(binId: number, routineName: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/DeleteCustomRoutine`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (routineName != null) {
            url += `${prefix}routineName=${encodeURIComponent(routineName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/RunCustomRoutineByName?binId=${binId}&routineName=${encodeURIComponent(routineName)}
    public runCustomRoutineByName(binId: number, routineName: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/RunCustomRoutineByName`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (routineName != null) {
            url += `${prefix}routineName=${encodeURIComponent(routineName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/setApiKey
    public setApiKey(secret: SecretDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/setApiKey`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(secret, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/syncBatch
    public syncBatchWithEdge(syncBatchDTO: SyncBatchDTO, signal?: AbortSignal): Promise<BatchDTO> {
        let url = `api/grainbin/syncBatch`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BatchDTO>(syncBatchDTO, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/downloadallloads
    public downloadAllLoads(signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/downloadallloads`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, true, _signal);
    }

    // post: api/grainbin/setWeatherMonitorBypass?azureDeviceId=${encodeURIComponent(azureDeviceId)}
    public setWeatherMonitorBypass(azureDeviceId: string, weatherMonitorSettingsDTO: WeatherMonitorChangeSettingsDTO, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/setWeatherMonitorBypass`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (azureDeviceId != null) {
            url += `${prefix}azureDeviceId=${encodeURIComponent(azureDeviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(weatherMonitorSettingsDTO, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/setBinTwin?azureDeviceId=${encodeURIComponent(azureDeviceId)}
    public setBinTwin(azureDeviceId: string, desiredPropertiesDTO: DesiredPropertiesDTO, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/setBinTwin`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (azureDeviceId != null) {
            url += `${prefix}azureDeviceId=${encodeURIComponent(azureDeviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(desiredPropertiesDTO, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/getecdevice?azureDeviceId=${encodeURIComponent(azureDeviceId)}
    public getECDevice(azureDeviceId: string, signal?: AbortSignal): Promise<GeneralDTO<ECDeviceDTO>> {
        let url = `api/grainbin/getecdevice`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (azureDeviceId != null) {
            url += `${prefix}azureDeviceId=${encodeURIComponent(azureDeviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, GeneralDTO<ECDeviceDTO>>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/getRemoteModTwin
    public getRemoteModuleTwin(secret: SecretDTO, signal?: AbortSignal): Promise<any> {
        let url = `api/grainbin/getRemoteModTwin`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, any>(secret, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/getLocalModTwin
    public getLocalModuleTwin(secret: SecretDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/getLocalModTwin`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(secret, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/getBinForAirTable
    public getBinForAirTable(secret: SecretDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/getBinForAirTable`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(secret, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/getWeatherForecast
    public getWeatherForecastForBin(secret: SecretDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/getWeatherForecast`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(secret, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/watchDogEndPoint
    public watchDogEndPoint(secretMsg: SecretDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/watchDogEndPoint`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(secretMsg, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/GetBinStateLogsByRange?clientDirectory=${encodeURIComponent(clientDirectory)}&startRange=${encodeURIComponent(String(startRange))}&endRange=${encodeURIComponent(String(endRange))}
    public getBinStateLogsByRange(clientDirectory: string, startRange: string, endRange: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/GetBinStateLogsByRange`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientDirectory != null) {
            url += `${prefix}clientDirectory=${encodeURIComponent(clientDirectory)}`;
            prefix = '&';
        }
        if (startRange != null) {
            url += `${prefix}startRange=${encodeURIComponent(startRange)}`;
            prefix = '&';
        }
        if (endRange != null) {
            url += `${prefix}endRange=${encodeURIComponent(endRange)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetGrainStateLogsByRange?clientDirectory=${encodeURIComponent(clientDirectory)}&startRange=${encodeURIComponent(String(startRange))}&endRange=${encodeURIComponent(String(endRange))}
    public getGrainStateeLogsByRange(clientDirectory: string, startRange: string, endRange: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/GetGrainStateLogsByRange`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientDirectory != null) {
            url += `${prefix}clientDirectory=${encodeURIComponent(clientDirectory)}`;
            prefix = '&';
        }
        if (startRange != null) {
            url += `${prefix}startRange=${encodeURIComponent(startRange)}`;
            prefix = '&';
        }
        if (endRange != null) {
            url += `${prefix}endRange=${encodeURIComponent(endRange)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetRunLogsByRange?clientDirectory=${encodeURIComponent(clientDirectory)}&startRange=${encodeURIComponent(String(startRange))}&endRange=${encodeURIComponent(String(endRange))}
    public getRunLogsByRange(clientDirectory: string, startRange: string, endRange: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/GetRunLogsByRange`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (clientDirectory != null) {
            url += `${prefix}clientDirectory=${encodeURIComponent(clientDirectory)}`;
            prefix = '&';
        }
        if (startRange != null) {
            url += `${prefix}startRange=${encodeURIComponent(startRange)}`;
            prefix = '&';
        }
        if (endRange != null) {
            url += `${prefix}endRange=${encodeURIComponent(endRange)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/getLatestSnapshot
    public getLatestBinStateSnapshotAnonymous(secretMsg: SecretDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/getLatestSnapshot`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(secretMsg, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/getbinassembly?binId=${binId}
    public getBinAssembly(binId: number, signal?: AbortSignal): Promise<BinAssemblyDTO> {
        let url = `api/grainbin/getbinassembly`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinAssemblyDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/GetScript
    public getScript(secretMsg: SecretDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/GetScript`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(secretMsg, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/latestErrors?deviceId=${encodeURIComponent(deviceId)}
    public getLatestErrors(deviceId: string, signal?: AbortSignal): Promise<ErrorListDTO> {
        let url = `api/grainbin/latestErrors`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ErrorListDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/latestErrorsFromDevice?deviceId=${encodeURIComponent(deviceId)}
    public getLatestErrorsFromDevice(deviceId: string, signal?: AbortSignal): Promise<ErrorListDTO> {
        let url = `api/grainbin/latestErrorsFromDevice`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ErrorListDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/allBins
    public getAllBins(signal?: AbortSignal): Promise<BinInfoDTO[]> {
        let url = `api/grainbin/allBins`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinInfoDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/getBinDetailFromAzure?deviceId=${encodeURIComponent(deviceId)}&binId=${binId}&offsetMinutes=${offsetMinutes}
    public getBinDetailFromAzure(deviceId: string, binId: number, offsetMinutes: number, signal?: AbortSignal): Promise<BinDTO> {
        let url = `api/grainbin/getBinDetailFromAzure`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (offsetMinutes != null) {
            url += `${prefix}offsetMinutes=${offsetMinutes}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/getBinDetailFromDevice?deviceId=${encodeURIComponent(deviceId)}&binId=${binId}&offsetMinutes=${offsetMinutes}
    public getBinDetailFromDevice(deviceId: string, binId: number, offsetMinutes: number, signal?: AbortSignal): Promise<BinDTO> {
        let url = `api/grainbin/getBinDetailFromDevice`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (offsetMinutes != null) {
            url += `${prefix}offsetMinutes=${offsetMinutes}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/UploadBinStateToAzure?deviceId=${encodeURIComponent(deviceId)}
    public uploadBinStateToAzure(deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/UploadBinStateToAzure`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/getSnapshotDetailFromAzure?binId=${binId}
    public getSnapshotDetailFromAzure(binId: number, signal?: AbortSignal): Promise<SnapshotDTO> {
        let url = `api/grainbin/getSnapshotDetailFromAzure`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, SnapshotDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/getCustomRoutinesOnDisk?binId=${binId}
    public getCustomRoutinesOnDisk(binId: number, signal?: AbortSignal): Promise<CustomRoutineNamesDTO> {
        let url = `api/grainbin/getCustomRoutinesOnDisk`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, CustomRoutineNamesDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/triggerBinStateUpload?deviceId=${encodeURIComponent(deviceId)}
    public triggerBinStateUpload(deviceId: string, signal?: AbortSignal): Promise<UploadResultDTO> {
        let url = `api/grainbin/triggerBinStateUpload`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UploadResultDTO>(null, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/pingDeviceAndGetBinDTOFromAzure?deviceId=${encodeURIComponent(deviceId)}&offsetMinutes=${offsetMinutes}
    public pingDeviceAndGetBinDTOFromAzure(deviceId: string, offsetMinutes: number, signal?: AbortSignal): Promise<PingAndBinDTO> {
        let url = `api/grainbin/pingDeviceAndGetBinDTOFromAzure`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }
        if (offsetMinutes != null) {
            url += `${prefix}offsetMinutes=${offsetMinutes}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, PingAndBinDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/PingDevice?deviceId=${encodeURIComponent(deviceId)}
    public pingDevice(deviceId: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/PingDevice`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/PingPremierPlusDevice?deviceId=${encodeURIComponent(deviceId)}
    public pingPremierPlusDevice(deviceId: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/PingPremierPlusDevice`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/PowerCycleBoard
    public powerCycleBoard(powerCycleBoardDTO: PowerCycleBoardDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/PowerCycleBoard`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(powerCycleBoardDTO, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/RestartDristackModule?deviceId=${encodeURIComponent(deviceId)}
    public restartDristackModule(deviceId: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/RestartDristackModule`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/GetLogsFromDristackmodule?deviceId=${encodeURIComponent(deviceId)}&lines=${lines}
    public getLogsFromDristackmodule(deviceId: string, lines: number, signal?: AbortSignal): Promise<DristackmoduleLogsDTO> {
        let url = `api/grainbin/GetLogsFromDristackmodule`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }
        if (lines != null) {
            url += `${prefix}lines=${lines}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, DristackmoduleLogsDTO>(null, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/ascii?deviceId=${encodeURIComponent(deviceId)}&binId=${binId}&offsetMinutes=${offsetMinutes}
    public getLatestBinAscii(deviceId: string, binId: number, offsetMinutes: number, signal?: AbortSignal): Promise<string> {
        let url = `api/grainbin/ascii`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (offsetMinutes != null) {
            url += `${prefix}offsetMinutes=${offsetMinutes}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, string>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/latestmcsnapshots/${encodeURIComponent(deviceID)}
    public getMoistureContentSnapshotsOfLatestBatch(deviceID: string, signal?: AbortSignal): Promise<MoistureContentSnapshotDTO[]> {
        let url = `api/grainbin/latestmcsnapshots/${encodeURIComponent(deviceID)}`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, MoistureContentSnapshotDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/latestBinStateSnapshot/${encodeURIComponent(deviceID)}
    public getLatestBinStateSnapshot(deviceID: string, signal?: AbortSignal): Promise<any> {
        let url = `api/grainbin/latestBinStateSnapshot/${encodeURIComponent(deviceID)}`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, any>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/gate/open?valveId=${encodeURIComponent(valveId)}&deviceName=${encodeURIComponent(deviceName)}
    public openGate(valveId: string, deviceName: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/gate/open`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (valveId != null) {
            url += `${prefix}valveId=${encodeURIComponent(valveId)}`;
            prefix = '&';
        }
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/gate/close?valveId=${encodeURIComponent(valveId)}&deviceName=${encodeURIComponent(deviceName)}
    public closeGate(valveId: string, deviceName: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/gate/close`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (valveId != null) {
            url += `${prefix}valveId=${encodeURIComponent(valveId)}`;
            prefix = '&';
        }
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/spool/open?valveId=${encodeURIComponent(valveId)}&deviceName=${encodeURIComponent(deviceName)}
    public openSpool(valveId: string, deviceName: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/spool/open`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (valveId != null) {
            url += `${prefix}valveId=${encodeURIComponent(valveId)}`;
            prefix = '&';
        }
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/spool/close?valveId=${encodeURIComponent(valveId)}&deviceName=${encodeURIComponent(deviceName)}
    public closeSpool(valveId: string, deviceName: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/spool/close`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (valveId != null) {
            url += `${prefix}valveId=${encodeURIComponent(valveId)}`;
            prefix = '&';
        }
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/fan/on?fanId=${encodeURIComponent(fanId)}&deviceName=${encodeURIComponent(deviceName)}
    public turnOnFan(fanId: string, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/fan/on`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (fanId != null) {
            url += `${prefix}fanId=${encodeURIComponent(fanId)}`;
            prefix = '&';
        }
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/fans/on?deviceName=${encodeURIComponent(deviceName)}
    public turnOnFans(deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/fans/on`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/fans/off?deviceName=${encodeURIComponent(deviceName)}
    public turnOffFans(deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/fans/off`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/fan/off?fanId=${encodeURIComponent(fanId)}&deviceName=${encodeURIComponent(deviceName)}
    public turnOffFan(fanId: string, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/fan/off`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (fanId != null) {
            url += `${prefix}fanId=${encodeURIComponent(fanId)}`;
            prefix = '&';
        }
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/heater/on?deviceName=${encodeURIComponent(deviceName)}&fanId=${encodeURIComponent(fanId)}
    public turnOnHeater(deviceName: string, fanId: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/heater/on`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }
        if (fanId != null) {
            url += `${prefix}fanId=${encodeURIComponent(fanId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/heaters/on?deviceName=${encodeURIComponent(deviceName)}
    public turnOnHeaters(deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/heaters/on`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/heater/off?deviceName=${encodeURIComponent(deviceName)}&fanId=${encodeURIComponent(fanId)}
    public turnOffHeater(deviceName: string, fanId: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/heater/off`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }
        if (fanId != null) {
            url += `${prefix}fanId=${encodeURIComponent(fanId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/heaters/off?deviceName=${encodeURIComponent(deviceName)}
    public turnOffHeaters(deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/heaters/off`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/compressor/on?deviceId=${encodeURIComponent(deviceId)}
    public turnOnCompressor(deviceId: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/compressor/on`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/compressor/off?deviceId=${encodeURIComponent(deviceId)}
    public turnOffCompressor(deviceId: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/compressor/off`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/SetIgnoredSolenoids
    public setIgnoredSolenoids(request: SetIgnoredSolenoidsRequestDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/SetIgnoredSolenoids`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(request, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetIgnoredSensors
    public setIgnoredSensors(request: SetIgnoredSensorRequestDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/SetIgnoredSensors`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(request, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetValvePositions?deviceId=${encodeURIComponent(deviceId)}
    public setValvePositions(deviceId: string, valvePositions: ValvePositionDTO[], signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/SetValvePositions`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(valvePositions, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/addUnload?deviceName=${encodeURIComponent(deviceName)}&binId=${binId}
    public addUnload(unload: UnloadDTO, deviceName: string, binId: number, signal?: AbortSignal): Promise<number> {
        let url = `api/grainbin/addUnload`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, number>(unload, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/addLoad?deviceName=${encodeURIComponent(deviceName)}&binId=${binId}&batchNumber=${batchNumber}
    public addLoad(load: LoadDTO, deviceName: string, binId: number, batchNumber: number, signal?: AbortSignal): Promise<number> {
        let url = `api/grainbin/addLoad`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (batchNumber != null) {
            url += `${prefix}batchNumber=${batchNumber}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, number>(load, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/deleteLoad?loadId=${loadId}&binId=${binId}&batchNumber=${batchNumber}
    public deleteLoad(loadId: number, binId: number, batchNumber: number, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/deleteLoad`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (loadId != null) {
            url += `${prefix}loadId=${loadId}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (batchNumber != null) {
            url += `${prefix}batchNumber=${batchNumber}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/deleteUnload?unloadId=${unloadId}&binId=${binId}&batchNumber=${batchNumber}
    public deleteUnload(unloadId: number, binId: number, batchNumber: number, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/deleteUnload`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (unloadId != null) {
            url += `${prefix}unloadId=${unloadId}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (batchNumber != null) {
            url += `${prefix}batchNumber=${batchNumber}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetWeatherMonitorSettings?deviceId=${encodeURIComponent(deviceId)}
    public setWeatherMonitorSettings(deviceId: string, settings: ReportedWeatherMonitorSettingsDTO, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/SetWeatherMonitorSettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(settings, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetGrainType?deviceId=${encodeURIComponent(deviceId)}
    public setGrainType(deviceId: string, body: SetGrainTypeRquestDTO, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/SetGrainType`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(body, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/Idle?deviceName=${encodeURIComponent(deviceName)}
    public idle(deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/Idle`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/Fill?deviceName=${encodeURIComponent(deviceName)}&binId=${binId}
    public fill(command: any, deviceName: string, binId: number, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/Fill`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/Dry?deviceName=${encodeURIComponent(deviceName)}&binId=${binId}
    public dry(command: any, deviceName: string, binId: number, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/Dry`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/ChangeHeaterMode
    public changeHeaterMode(changeHeaterModeDTO: ChangeHeaterModeDTO, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/ChangeHeaterMode`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(changeHeaterModeDTO, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetHeaterMode
    public setHeaterMode(options: HeaterModeOptionsRequestDTO, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/SetHeaterMode`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(options, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetHeaterPlenumMcOffset?heaterPlenumMcOffset=${heaterPlenumMcOffset}&deviceId=${encodeURIComponent(deviceId)}
    public setHeaterPlenumMcOffset(heaterPlenumMcOffset: number, deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/SetHeaterPlenumMcOffset`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (heaterPlenumMcOffset != null) {
            url += `${prefix}heaterPlenumMcOffset=${heaterPlenumMcOffset}`;
            prefix = '&';
        }
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/IgnorePlenumTemperatureSensor?ignorePlenumTemperatureSensor=${ignorePlenumTemperatureSensor}&deviceId=${encodeURIComponent(deviceId)}
    public ignorePlenumTemperatureSensors(ignorePlenumTemperatureSensor: boolean, deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/IgnorePlenumTemperatureSensor`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ignorePlenumTemperatureSensor != null) {
            url += `${prefix}ignorePlenumTemperatureSensor=${ignorePlenumTemperatureSensor}`;
            prefix = '&';
        }
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetFanRemainingOnTime
    public setFanRemainingOnTime(options: FanOnAllowedTimeRequestDTO, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/SetFanRemainingOnTime`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(options, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetFanRemainingOnTimePerWeek
    public setFanRemainingOnTimePerWeek(options: FanOnAllowedTimePerWeekRequestDTO, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/SetFanRemainingOnTimePerWeek`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(options, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/ResetFanRuntime?deviceId=${encodeURIComponent(deviceId)}
    public resetFanRuntime(deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/ResetFanRuntime`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/UpdateMaxLayerGrainTemp
    public updateMaxLayerGrainTemp(options: UpdateMaxLayerGrainTempRequestDTO, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/UpdateMaxLayerGrainTemp`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(options, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SetLayersTimePercentages?deviceId=${encodeURIComponent(deviceId)}
    public setLayersTimePercentages(deviceId: string, layerPercentages: LayerPercentageDTO[], signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/SetLayersTimePercentages`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(layerPercentages, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/ClearRoutineEngineSteps?deviceId=${encodeURIComponent(deviceId)}
    public clearRoutineEngineSteps(deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/ClearRoutineEngineSteps`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/RunCoreManualRoutine?deviceId=${encodeURIComponent(deviceId)}
    public runCoreManualRoutine(deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/RunCoreManualRoutine`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/CloseAllValvesAndClearRoutines?deviceId=${encodeURIComponent(deviceId)}
    public closeAllValvesAndClearRoutines(deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/CloseAllValvesAndClearRoutines`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/ClearManualRoutine?deviceId=${encodeURIComponent(deviceId)}
    public clearManualRoutine(deviceId: string, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/ClearManualRoutine`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/RunManualLayer?deviceId=${encodeURIComponent(deviceId)}&targetLayer=${targetLayer}
    public runManualLayer(deviceId: string, targetLayer: number, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/RunManualLayer`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }
        if (targetLayer != null) {
            url += `${prefix}targetLayer=${targetLayer}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/Storage?deviceName=${encodeURIComponent(deviceName)}
    public storage(command: any, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/Storage`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/Unload?deviceName=${encodeURIComponent(deviceName)}
    public unload(command: any, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/Unload`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/StartManual?deviceName=${encodeURIComponent(deviceName)}
    public startManual(command: any, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/StartManual`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/StopManual?deviceName=${encodeURIComponent(deviceName)}
    public stopManual(deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/StopManual`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/Pause?deviceName=${encodeURIComponent(deviceName)}
    public pause(command: any, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/Pause`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/Resume?deviceName=${encodeURIComponent(deviceName)}
    public resume(command: any, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/Resume`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/SelfCheck?deviceName=${encodeURIComponent(deviceName)}
    public selfCheck(command: any, deviceName: string, signal?: AbortSignal): Promise<boolean> {
        let url = `api/grainbin/SelfCheck`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceName != null) {
            url += `${prefix}deviceName=${encodeURIComponent(deviceName)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(command, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/getOfflineConnectionReport?deviceId=${encodeURIComponent(deviceId)}
    public getOfflineConnectionReport(deviceId: string, signal?: AbortSignal): Promise<OfflineBinReportDTO> {
        let url = `api/grainbin/getOfflineConnectionReport`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, OfflineBinReportDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetHourlyForecast?binId=${binId}
    public getHourlyForecast(binId: number, signal?: AbortSignal): Promise<HourlyDTO[]> {
        let url = `api/grainbin/GetHourlyForecast`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, HourlyDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetMoistureForecast?binId=${binId}
    public getMoistureForecast(binId: number, signal?: AbortSignal): Promise<MoistureForecastDTO[]> {
        let url = `api/grainbin/GetMoistureForecast`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, MoistureForecastDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/PingTest
    public pingTest(signal?: AbortSignal): Promise<string> {
        let url = `api/grainbin/PingTest`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, string>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/updateFanSettings?deviceId=${encodeURIComponent(deviceId)}
    public updateSettings(settings: DesiredRequestSettingsDTO, deviceId: string, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/updateFanSettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (deviceId != null) {
            url += `${prefix}deviceId=${encodeURIComponent(deviceId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(settings, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/takeCameraPicture?binId=${binId}
    public takeCameraPicture(binId: number, signal?: AbortSignal): Promise<ResultCameraDTO> {
        let url = `api/grainbin/takeCameraPicture`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResultCameraDTO>(null, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/getCameraImage?binId=${binId}
    public getCameraImage(binId: number, signal?: AbortSignal): Promise<ResponseCameraImageDTO> {
        let url = `api/grainbin/getCameraImage`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseCameraImageDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/StartLiveStream?binId=${binId}
    public startLiveStream(binId: number, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/StartLiveStream`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/StopLiveStream?binId=${binId}
    public stopLiveStream(binId: number, signal?: AbortSignal): Promise<ResponseResultDTO> {
        let url = `api/grainbin/StopLiveStream`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ResponseResultDTO>(null, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/GetStreamsForDevice?binId=${binId}
    public getStreamsForDevice(binId: number, signal?: AbortSignal): Promise<LiveStreamForDeviceDTO> {
        let url = `api/grainbin/GetStreamsForDevice`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, LiveStreamForDeviceDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetCellularStrengthHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getCellularStrengthHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<CellStrengthDatasetDTO> {
        let url = `api/grainbin/GetCellularStrengthHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, CellStrengthDatasetDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetOPICableHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getOPICableHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<OpiCableDatasetDTO[]> {
        let url = `api/grainbin/GetOPICableHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, OpiCableDatasetDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetBinSenseCableHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getBinSenseCableHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<BinSenseCableDatasetDTO[]> {
        let url = `api/grainbin/GetBinSenseCableHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, BinSenseCableDatasetDTO[]>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/GetAlertHistory
    public getBinAlertHistory(dto: AlertHistoryRequestDTO, signal?: AbortSignal): Promise<AlertHistoryResponseDTO> {
        let url = `api/grainbin/GetAlertHistory`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, AlertHistoryResponseDTO>(dto, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/GetFanHeaterChart?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getFanHeaterChart(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<FanHeaterHistoryChartResponseDTO> {
        let url = `api/grainbin/GetFanHeaterChart`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, FanHeaterHistoryChartResponseDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetLayerSummaryHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getLayerSummaryHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<LayerSummaryDatasetDTO[]> {
        let url = `api/grainbin/GetLayerSummaryHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, LayerSummaryDatasetDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetLowestLayerTemperatureHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getLowestLayerTemperatureHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<LayerSummaryDatasetDTO[]> {
        let url = `api/grainbin/GetLowestLayerTemperatureHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, LayerSummaryDatasetDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetTemperatureCableHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getTemperatureCableHistoryDTO(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<DatasetDoubleDTO[]> {
        let url = `api/grainbin/GetTemperatureCableHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, DatasetDoubleDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetCO2History?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getCO2History(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<CO2DatasetDTO> {
        let url = `api/grainbin/GetCO2History`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, CO2DatasetDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetAmbientPlenumHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getAmbientPlenumHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<DatasetDoubleDTO[]> {
        let url = `api/grainbin/GetAmbientPlenumHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, DatasetDoubleDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetMoistureRemovalData?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getMoistureRemovalData(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<MoistureRemovalDatapointDTO[]> {
        let url = `api/grainbin/GetMoistureRemovalData`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, MoistureRemovalDatapointDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetAmbientPlenumEMCHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getAmbientPlenumEMCHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<DatasetDoubleDTO[]> {
        let url = `api/grainbin/GetAmbientPlenumEMCHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, DatasetDoubleDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetCompressorDataset?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getCompressorDataset(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<DatasetDoubleDTO[]> {
        let url = `api/grainbin/GetCompressorDataset`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, DatasetDoubleDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetOperatingModeChangesDataset?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getOperatingModeChangesDataset(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<ModeChangeHistoryDatasetDTO> {
        let url = `api/grainbin/GetOperatingModeChangesDataset`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ModeChangeHistoryDatasetDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetFanHeaterChangeHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getFanHeaterChangeHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<FanHeaterChangeTimeDatapointDTO[]> {
        let url = `api/grainbin/GetFanHeaterChangeHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, FanHeaterChangeTimeDatapointDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetTargetLayerChart?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getTargetLayerChart(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<TargetLayerDatasetDTO> {
        let url = `api/grainbin/GetTargetLayerChart`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, TargetLayerDatasetDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetGrainHeightsHistory?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getGrainHeightsHistory(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<RangeFinderDatapointDTO[]> {
        let url = `api/grainbin/GetGrainHeightsHistory`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, RangeFinderDatapointDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/GetGrainHeightsHistoryUserEntered?binId=${binId}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}&intervalSeconds=${intervalSeconds}
    public getGrainHeightsHistoryUserEntered(binId: number, from: string, to: string, intervalSeconds?: number | null, signal?: AbortSignal): Promise<UserLoadDatapointDTO[]> {
        let url = `api/grainbin/GetGrainHeightsHistoryUserEntered`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }
        if (intervalSeconds != null) {
            url += `${prefix}intervalSeconds=${intervalSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserLoadDatapointDTO[]>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/OverrideGrainHeight?binId=${binId}&eaveHeightFt=${eaveHeightFt}&peakHeightFt=${peakHeightFt}
    public overrideGrainHeight(binId: number, eaveHeightFt?: number | null, peakHeightFt?: number | null, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/OverrideGrainHeight`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (eaveHeightFt != null) {
            url += `${prefix}eaveHeightFt=${eaveHeightFt}`;
            prefix = '&';
        }
        if (peakHeightFt != null) {
            url += `${prefix}peakHeightFt=${peakHeightFt}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // get: api/grainbin/CalculateGrainHeight?binId=${binId}&eaveHeightFt=${eaveHeightFt}&peakHeightFt=${peakHeightFt}
    public calculateGrainHeight(binId: number, eaveHeightFt?: number | null, peakHeightFt?: number | null, signal?: AbortSignal): Promise<CalculateGrainHeightResponseDTO> {
        let url = `api/grainbin/CalculateGrainHeight`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (eaveHeightFt != null) {
            url += `${prefix}eaveHeightFt=${eaveHeightFt}`;
            prefix = '&';
        }
        if (peakHeightFt != null) {
            url += `${prefix}peakHeightFt=${peakHeightFt}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, CalculateGrainHeightResponseDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/grainbin/getPremierPlusBinDetailFromAzure?binId=${binId}&offsetMinutes=${offsetMinutes}
    public getPremierPlusBinDetailFromAzure(binId: number, offsetMinutes: number, signal?: AbortSignal): Promise<PremierPlusBinDTO> {
        let url = `api/grainbin/getPremierPlusBinDetailFromAzure`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }
        if (offsetMinutes != null) {
            url += `${prefix}offsetMinutes=${offsetMinutes}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, PremierPlusBinDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/grainbin/modifyBasicSettings?binId=${binId}
    public premierPlus_ModifyBasicSettings(binId: number, settings: ConfigureBasicSettingsRequestDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/modifyBasicSettings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (binId != null) {
            url += `${prefix}binId=${binId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(settings, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/registerProduct
    public registerProduct(request: RegisterProductDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/registerProduct`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(request, 'post', url, true, false, _signal);
    }

    // post: api/grainbin/updateSystemConfigRegistry
    public updateSystemConfigRegistry(request: ConfigureSystemRequestDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/grainbin/updateSystemConfigRegistry`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(request, 'post', url, true, false, _signal);
    }
}
var service = new BinApiService();
export default service;
