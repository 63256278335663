// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum BoardGroup {
    All = 0,
    Hub = 1,
    Fans = 2,
    FanHeaterExpansionBoards = 21,
    AugerControlBoards = 22,
    LevelDryBoards = 23,
    HeadspaceBoards = 24,
    StackBoards = 3,
    SensingBoards = 4,
    RHTBoards = 5,
    OPIBoards = 6,
    CmcSensorBoards = 7,
}
export default BoardGroup;
