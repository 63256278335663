import { Button, Card, Col, Descriptions, Row, Space, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../../pages/features/PremierPlus/shared";
import { RedoOutlined } from "@ant-design/icons";
import { useGetPremierPlusBinDto } from "src/queries/useGetPremierPlusBinDto";
import { useEcDeviceQuery, useOfflineReport } from "src/components/BinDetails";
import CustomTag from "src/components/CustomTag";
import { CheckFailedIcon, CheckPassedIcon } from "src/pages/features/WeatherMonitorAntdOnly";

interface PremierPlusOverviewProps {
    layout: "horizontal" | "vertical"; 
    binId: number;
    binName: string;
    deviceId: string;
}

const { Text } = Typography;

const PremierPlusOverview : React.FC<PremierPlusOverviewProps> = ({ binId, binName, deviceId, layout }) => {

    const [content, setContent] = useState<React.ReactNode>();

    const ecDeviceQuery = useEcDeviceQuery(deviceId, true);
    const { getPremierPlusBinDtoQuery } = useGetPremierPlusBinDto(binId, { enabled: true, refetchOnWindowFocus: false, retry: false });
    const getOfflineConnectionReport = useOfflineReport(deviceId, false);

    useEffect( () => {
        if(getPremierPlusBinDtoQuery.data){
            setContent(
                <>
                    <p style={{ color:"grey" }}>{getPremierPlusBinDtoQuery.data?.snapshot?.captureTimeUTC != null ? dayjs(getPremierPlusBinDtoQuery.data?.snapshot?.captureTimeUTC)?.format(DATETIME_FORMAT) : null}</p>
                    <Descriptions bordered layout={layout} size="small" column={1}>
                        <Descriptions.Item label={'Relative Humidity'}>
                            {getPremierPlusBinDtoQuery.data?.snapshot?.relativeHumidityPercentage ?? 0}
                        </Descriptions.Item>

                        <Descriptions.Item label={'Avg. Moisture'}>
                            {getPremierPlusBinDtoQuery.data?.snapshot?.avgMoisturePercentage ?? 0}
                        </Descriptions.Item>

                        <Descriptions.Item label={'Grain Temp'}>
                            {getPremierPlusBinDtoQuery.data?.snapshot?.grainTempF ?? 0}
                        </Descriptions.Item>

                        <Descriptions.Item label={'Plenum Temp'}>
                            {getPremierPlusBinDtoQuery.data?.snapshot?.plenumTempF ?? 0}
                        </Descriptions.Item>

                        <Descriptions.Item label={'Target Temp'}>
                            {getPremierPlusBinDtoQuery.data?.snapshot?.targetTempF ?? 0}
                        </Descriptions.Item>

                        <Descriptions.Item label={'Machine'}>
                            <Space direction="horizontal" wrap size="small">
                                {getPremierPlusBinDtoQuery.data?.snapshot?.isMachineOn}
                                {getPremierPlusBinDtoQuery.data?.snapshot?.isMachineOn ? <CheckPassedIcon /> : <CheckFailedIcon />}
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>
                </>
            );
        }else{
            getOfflineConnectionReport.refetch();
        }
    }, [getPremierPlusBinDtoQuery.dataUpdatedAt]);
    
    const offlineContext = useMemo( () => {

        if(getOfflineConnectionReport.data?.connectionState != 'Connected'){
            return(
                <>
                    <Row justify={'center'}> 
                        <CustomTag 
                        label={'SYSTEM OFFLINE'}
                        variant='secondary' 
                        bold={true}
                        />
                    </Row>
                    <Row>
                        <Col>
                            <p>Last Time connected: {dayjs(getOfflineConnectionReport.data?.moduleTwinLastActivityTime).format(DATETIME_FORMAT)}</p>
                            <p>Device is connected? {ecDeviceQuery.data?.context?.connection?.status ?? 'false'}</p>
                        </Col>
                    </Row>
                </>
            )
        }

        return null;

    }, [getOfflineConnectionReport.dataUpdatedAt, ecDeviceQuery.dataUpdatedAt]);

    return (
        <Card
        loading={getPremierPlusBinDtoQuery.isLoading}
        title={
            <Row justify="start" align="middle">
                <Col>
                    {binName}
                </Col>
                <Col style={{ paddingLeft: 8 }}>
                    <CustomTag label={'Premier+'}/>
                </Col>
            </Row>
        }
        headStyle={{ background: '#939393', color: '#000000' }}
        extra={
            <Row gutter={8} justify="end">
                <Col>
                    <Button 
                    ghost={false}
                    type="primary"
                    onClick={() => {getPremierPlusBinDtoQuery.refetch()}}
                    >
                        <RedoOutlined spin={getPremierPlusBinDtoQuery.isLoading} style={{ fontSize: "20px" }}/></Button>
                </Col>
                <Col>
                    <Button
                    ghost={false}
                    type="primary"
                    >
                        Details
                    </Button>
                </Col>
            </Row>
        }>
            <Row justify={"center"} align="middle">
                <Col span={"400px"}>
                    {offlineContext}
                    {content}
                </Col>
            </Row>
        </Card>
    )
}

export default PremierPlusOverview;