import * as React from 'react';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import EnterpriseDTO from 'src/models/EnterpriseDTO';
import SiteDTO from 'src/models/SiteDTO';
import {
    Col,
    Layout,
    notification,
    Row,
    Spin,
    Result,
    Button,
} from 'antd';
import BinDetails from '../../../components/BinDetails';
import BinInfoDTO from 'src/models/BinInfoDTO';
import GrowerDTO from 'src/models/GrowerDTO';
import { RouteComponentProps } from 'react-router-dom';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { connect } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { binDBKeys } from 'src/pages/binOverview/BinCommander';
import { useQueryClient } from '@tanstack/react-query';
import { PageHeader } from '@ant-design/pro-layout';
import { ExtraOfflineInfoContext } from '../BinOfflineIndicator';
import { GrowerSlim } from 'src/redux/actions/UserSessionActions';
import GrowerSelection from './GrowerSelection';
import RegisterProductModal from 'src/components/RegisterProductModal';
import RoleUtil from 'src/utils/RoleUtil';

interface HomeProps {
    growerID: number | null;
    grower: GrowerSlim;
}

interface State {
    model: SiteDTO;
    loading: boolean;
    enterprise: EnterpriseDTO;
    currentGrower: number | null;
    grower?: GrowerDTO;
    homeTitle: string | null;
    visible: boolean;
    siteName: string;
    location: string;
    targetValue: any;
    targetValidate: '' | 'error' | 'success' | 'warning' | 'validating' | undefined;
    offline: boolean;
    showExtraOfflineInfoForBins: boolean,
}


const Home = (props: RouteComponentProps & HomeProps) => {
    const queryClient = useQueryClient();
    const [registerProductModalOpen, setRegisterProductModalOpen]= useState(false);

    const handleClickRegisterProduct = () =>{
        setRegisterProductModalOpen(true);
    }

    const handleCloseRegisterProductModal = () => {
        setRegisterProductModalOpen(false);
        fetchData(props.grower);
    }

    const initialstate: State = {
        model: SiteDTO.create(),
        loading: true,
        enterprise: EnterpriseDTO.create(),
        homeTitle: '',
        visible: false,
        siteName: '',
        location: '',
        targetValue: null,
        targetValidate: '',
        currentGrower: props.growerID ?? 1,
        offline: false,
        showExtraOfflineInfoForBins: false,
    };
    const [state, setState] = useState<State>(initialstate);

    const fetchData = useCallback( async (grower: GrowerSlim | null) => {

        const growerID = grower?.growerId;
        if (growerID) {
            setState((prev) => { return { ...prev, loading: true }; });
            EnterpriseApiService.getEnterpriseDetail(growerID).then(enterprise => {
                // pass db data to server cache of individual bin binInfo
                enterprise?.grower?.bins?.forEach((bin) => {
                    queryClient.setQueryData(binDBKeys.binInfo(bin.id), (oldData: BinInfoDTO | undefined) => {
                        if (bin?.id == null) {
                            return undefined;
                        }
                        return bin;
                    });
                });
                setState((prev) => {
                    return {
                        ...prev,
                        enterprise: enterprise,
                        grower: enterprise.grower!,
                        targetValue: null,
                        loading: false
                    };
                }
                );
            }).catch(error => {
                setState((prev) => { return { ...prev, loading: false, offline: true }; });
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        }

        if (grower?.externalId) {

            setState((prev) => { return { ...prev, loading: true }; });
            EnterpriseApiService.getEnterpriseDetailByExternalId(Number.parseInt(grower?.externalId!, 10)).then(enterprise => {
                // pass db data to server cache of individual bin binInfo
                enterprise?.grower?.bins?.forEach((bin) => {
                    queryClient.setQueryData(binDBKeys.shivversBin(bin.linkIpAddress!), (oldData: BinInfoDTO | undefined) => {
                        if (bin?.linkIpAddress == null) {
                            return undefined;
                        }
                        return bin;
                    });
                });
                setState((prev) => {
                    return {
                        ...prev,
                        enterprise: enterprise,
                        grower: enterprise.grower!,
                        targetValue: null,
                        loading: false
                    };
                }
                );
            }).catch(error => {
                setState((prev) => { return { ...prev, loading: false, offline: true }; });
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        }
    }, [queryClient]);

    useEffect(() => {
        
        fetchData(props.grower);
    }, [props.grower]);

    const setLoading = useCallback((val: boolean, i: number) => {
        setState((prev) => { return { ...prev, loading: val }; });
    }, [setState]);

    const getBins = (binLength: number, bins: BinInfoDTO[] | null | undefined) => {
        if (bins) {
            return (
                bins.map((bin: BinInfoDTO, i: number) => (
                    <BinDetails
                        bin={bin}
                        offlineReport={null}
                        binLength={binLength}
                        targetValue={state.targetValue}
                        key={`${bin.id}-${bin.name}-${bin.linkIpAddress}-${bin.externalId}`}
                        id={i}
                        isLoading={setLoading} />
                )));
        } else {
            return <></>;
        }
    }

    const { offline } = state;
    let binLength = state.grower?.bins?.length || 0;

    if (offline) {
        return (
            <Layout.Content className="dashboard" style={{ height: '100%' }}>

                <PageHeader
                title={state.homeTitle}
                style={{ width: '100%' }} 
                />

                <Row align="middle">
                    <Col span={24} style={{ width: '100%' }}>
                        <Result status="warning" title="Error Getting Bin Overview" />
                    </Col>
                </Row>

            </ Layout.Content>
        );
    } else {

        return (
            <Layout.Content className="dashboard" style={{paddingBottom: "0px"}} >

                <Row style={{ paddingBottom: 0 }} gutter={24}>
                    <PageHeader
                    className="formButton"
                    //title={state.homeTitle}
                    title={<GrowerSelection />}
                    style={{ width: '100%', paddingRight: 0, paddingLeft: 8, paddingBottom: 0 }}
                    />
                </Row>
                
                <Spin spinning={state.loading} style={{ paddingBottom: 400, height: '100%' }} tip="loading...">
                    <ExtraOfflineInfoContext.Provider value={{showExtraOfflineInfo: state.showExtraOfflineInfoForBins, toggleOfflineInfo: () => setState({...state, showExtraOfflineInfoForBins: !state.showExtraOfflineInfoForBins})}}>
                        {
                            state.grower?.bins?.length != 0 ?
                            <Row style={{ paddingBottom: state.loading ? 300 : 20 }} align="top" gutter={{ xs: 8, sm: 8, md: 16, lg: 16, xl: 16 }}>
                                {getBins(binLength, state.grower?.bins)}
                            </Row> : 
                            
                            <Row justify={'center'}>
                                <Col>
                                    <p style={{ textAlign:'center', color: 'grey' }}>No systems found</p>
                                    <Button
                                    onClick={handleClickRegisterProduct}
                                    type="primary">
                                        +Click here to register your product
                                    </Button>
                                </Col>
                            </Row>
                        }
                        
                        <RegisterProductModal 
                        modalOpen={registerProductModalOpen} 
                        closeModal={handleCloseRegisterProductModal} 
                        growerId={props?.growerID ?? 0}/>

                    </ExtraOfflineInfoContext.Provider>
                </Spin>
            </ Layout.Content>
        );
    }

};


function mapStateToProps(state: StateStoreModel) {
    return {
        growerID: state.UserSession.GrowerID,
        growerName: state.UserSession.GrowerName,
        grower: state.UserSession.grower,
    };
}

export default connect(mapStateToProps, null)(Home);
