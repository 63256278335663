import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { Line } from 'react-chartjs-2';
import _uniqueId from 'lodash/uniqueId';


import React, { useCallback, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartType,
  Plugin,
  LegendItem,
  ChartTypeRegistry,
  Point,
  BubbleDataPoint,
} from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
// @ts-ignore
import autocolors from 'chartjs-plugin-autocolors';
// @ts-ignore
import { useQuery } from '@tanstack/react-query';
import { binDBKeys } from 'src/pages/binOverview/BinCommander';
import { useBinStateFromAzure } from 'src/queries/useBinStateFromAzure';
import { useBinStateFromDevice } from 'src/queries/useBinStateFromDevice';
import { getUserTimezoneOffset } from '../../../components/BinDetails';
import { Card, Radio, Skeleton, Space, Switch, Typography } from 'antd';
import BinApiService from 'src/api/BinApiService';
import { queryClient } from 'src';
import SensorType from 'src/consts/SensorType';
import ReactDOM from 'react-dom';
import { ChartDatePicker } from './Co2Chart';
import { RangeValue } from './ChartContainer';
import { round } from 'lodash';
import { tooltipItemRound, useFanHeaterChangeHistory } from './shared';
import OpiCableDatapointDTO from 'src/models/OpiCableDatapointDTO';
import { useModeChangeHistory } from './LayerMCHistoryChart';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, autocolors,
);

enum LegendView {
  Cable = "Cable",
  Layer = "Layer",
}

interface CustomLegendProps {
  chart: ChartJS<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown>;
  layerSections: Map<string, Array<LegendItem>>;
  cableSections: Map<string, Array<LegendItem>>;
}

const CustomLegend = (props: CustomLegendProps) => {

  const chart = props.chart;
  const [toggle, setToggle] = useState<LegendView>(LegendView.Layer);

  const [showLegend, setShowLegend] = useState(false);

  const layerSections = props.layerSections;
  const cableSections = props.cableSections;

  return (<>
  <Space direction='horizontal' size="middle" wrap align='start'>
  <Radio.Group size="small" defaultValue={toggle} buttonStyle='solid' onChange={(e) => {
    setToggle(e.target.value);
  }}>
    <Radio.Button value={LegendView.Cable}>By Cable</Radio.Button>
    <Radio.Button value={LegendView.Layer}>By Layer</Radio.Button>
  </Radio.Group>

<Space direction='horizontal' wrap>
    <span>Show Legend</span>
    <Switch defaultChecked={showLegend} onChange={(checked) => setShowLegend(checked)} />
  </Space>
  </Space>

{showLegend && <>
{toggle === LegendView.Layer && [...layerSections.entries()].reverse().map(([layerId, items]) => {
        return (<section key={layerId} style={{ justifyContent: "center" }}>
          <p style={{ fontWeight: "bold", minWidth: "120px", cursor: "pointer"}} onClick={() => {
            // @ts-ignore
            const { type } = chart.config;
            const someVisible = items.some((item: any) => {
              return chart.isDatasetVisible(item.datasetIndex);
            });
            items.forEach((item: any) => {
              if (type === 'pie' || type === 'doughnut') {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
              } else {
                chart.setDatasetVisibility(item.datasetIndex, !someVisible);
              }
            })
            chart.update();
          }}>Layer {layerId}</p>

          <div className="chart-list" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: 0, padding: 0, paddingBottom: "16px", gap: "16px" }}>
            {items.map(item => {
              return (
                <div key={item.text} className='legend-item'
                  style={{ alignItems: "center", cursor: "pointer", display: "flex", flexDirection: "row", marginLeft: "8px" }}
                  onClick={() => {
                    // @ts-ignore
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                      // Pie and doughnut charts only have a single dataset and visibility is per item
                      chart.toggleDataVisibility(item.index!);
                    } else {
                      chart.setDatasetVisibility(item.datasetIndex!, !chart.isDatasetVisible(item.datasetIndex!));
                    }
                    chart.update();
                  }}
                >
                  <span style={{ background: item.fillStyle as string, borderColor: item.strokeStyle as string, borderWidth: item.lineWidth + "px", display: 'inline-block', height: "20px", marginRight: "8px", width: "20px" }}>
                  </span>
                  <p style={{ color: item.fontColor as string, margin: 0, padding: 0, textDecoration: item.hidden ? 'line-through' : '' }}>
                    {item.text}
                  </p>
                </div>);
            })}
          </div>
        </section>);
      })}


      {toggle === LegendView.Cable && [...cableSections.entries()].map(([cableId, items]) => {
        return (<section key={cableId} style={{ justifyContent: "center" }}>
          <p style={{ fontWeight: "bold", minWidth: "120px", cursor: 'pointer'}} onClick={() => {
            // @ts-ignore
            const { type } = chart.config;
            const someVisible = items.some((item: any) => {
              return chart.isDatasetVisible(item.datasetIndex);
            });
            items.forEach((item: any) => {
              if (type === 'pie' || type === 'doughnut') {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
              } else {
                chart.setDatasetVisibility(item.datasetIndex, !someVisible);
              }
            })
            chart.update();
          }}>{cableId}</p>

          <div className="chart-list" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: 0, padding: 0, paddingBottom: "16px", gap: "16px" }}>
            {items.map(item => {
              return (
                <div key={item.text} className='legend-item'
                  style={{ alignItems: "center", cursor: "pointer", display: "flex", flexDirection: "row", marginLeft: "8px" }}
                  onClick={() => {
                    // @ts-ignore
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                      // Pie and doughnut charts only have a single dataset and visibility is per item
                      chart.toggleDataVisibility(item.index!);
                    } else {
                      chart.setDatasetVisibility(item.datasetIndex!, !chart.isDatasetVisible(item.datasetIndex!));
                    }
                    chart.update();
                  }}
                >
                  <span style={{ background: item.fillStyle as string, borderColor: item.strokeStyle as string, borderWidth: item.lineWidth + "px", display: 'inline-block', height: "20px", marginRight: "8px", width: "20px" }}>
                  </span>
                  <p style={{ color: item.fontColor as string, margin: 0, padding: 0, textDecoration: item.hidden ? 'line-through' : '' }}>
                    {item.text}
                  </p>
                </div>);
            })}
          </div>
        </section>);
      })}
      </>}
  </>);
}

export const htmlLegendPlugin: Plugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    if (chart == null) {
      return;
    }

    // const ul = getOrCreateLegendList(chart, options.containerID);

    // // Remove old legend items
    // while (ul.firstChild) {
    //   ul.firstChild.remove();
    // }
    // Reuse the built-in legendItems generator
    const items = chart?.options?.plugins?.legend?.labels?.generateLabels?.(chart)!;


    const layerSections = new Map<string, LegendItem[]>();

    items.forEach(item => {


      // @ts-ignore
      if (layerSections.has(chart.data.datasets[item.datasetIndex!].layerId)) {
        // @ts-ignore
        layerSections.get(chart.data.datasets[item.datasetIndex!].layerId).push(item);
      }
      else {
        // @ts-ignore
        layerSections.set(chart.data.datasets[item.datasetIndex!].layerId, [item]);
      }
    });

    const cableSections = new Map<string, LegendItem[]>();
    items.forEach(item => {


      // @ts-ignore
      if (cableSections.has(chart.data.datasets[item.datasetIndex!].cableId)) {
        // @ts-ignore
        cableSections.get(chart.data.datasets[item.datasetIndex!].cableId).push(item);
      }
      else {
        // @ts-ignore
        cableSections.set(chart.data.datasets[item.datasetIndex!].cableId, [item]);
      }
    });

    const groups: Node[] = [];

    const legendSectionOverview = document.createElement("section");
    const legendContainer = document.getElementById(options.containerID);
    //legendContainer?.replaceChildren(...groups);


    // React

    const toRender = <div>
      <CustomLegend chart={chart} cableSections={cableSections} layerSections={layerSections} />
    </div>;

    ReactDOM.render(toRender, legendContainer);


    //ul.appendChild(li);
  }
};

const currentTime = dayjs();

let labels: string[] = [];
for (let i = 0; i < 7; i++) {
  labels.push(currentTime.add(5 * i, 'minutes').toISOString());
}

export interface BaseChartProps {
  binId: number,
  deviceId: string,
  value?: RangeValue,
  onChange?: (value: RangeValue) => void;
  min?: number;
  max?: number;
  chartHeight?: number | string;
  // height of container containing chart if multiple
  containerMinHeight?: number | string;
}



export const TemperatureCableChart = (props: BaseChartProps) => {
  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');
  const [legendId] = useState(_uniqueId('legend-'));

  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
	// the component is controlled or not, that value comes from its
	// props or from its internal state.

	const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "TemperatureCables", { startDate: value?.[0], endDate: value?.[1] }],
    queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];

      const result = await BinApiService.getTemperatureCableHistoryDTO(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
      return result;
    },
    refetchInterval: Infinity, retry: false, keepPreviousData: true,
    enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null,
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);


  return (<div>
    {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
    <Skeleton loading={chartQuery.data == null}>
      {chartQuery.data && <>
        <section style={{ width: "100%", height: props.chartHeight ?? "480px", display: "flex", justifyContent: "center" }}>
          <Line options={{
            responsive: true,
            animation: false,
            spanGaps: false,
            maintainAspectRatio: false,
            datasets: {
              line: {
                pointRadius: 0 // disable for all `'line'` datasets
              }
            },
            scales: {
              x: {
                type: "time",
                time: {
                  minUnit: 'day',
                  displayFormats: {
                    //day: 'l'
                  }
                },
              },
              y: {
                title: {
                  font: {
                    size: 16,
                  },
                  display: true,
                  //text: "Temperature °F",
                  text: chartQuery.data?.[0]?.unitLabel!,
                }
              }
            },
            plugins: {
              annotation: {
                annotations: [
                  ...modeHistory.annotations,
                  ...fanHeaterChangeHistory.annotations,
                ],
              },
              legend: {
                position: 'top' as const,
                display: false,
              },
              // @ts-ignore
              htmlLegend: {
                // ID of the container to put the legend in
                containerID: `legend-container-temp-cable-${legendId}`,
              },
              title: {
                display: true,
                text: 'Temperature Cables',
              },

              tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: tooltipItemRound,
                },
              },

            },
          }}
            // @ts-ignore
            data={{ datasets: chartQuery.data! }} plugins={[htmlLegendPlugin]} />
        </section>
        <section id={`legend-container-temp-cable-${legendId}`}></section>
      </>
      }
    </Skeleton>

  </div>);
}

export const TemperatureCableOpiChart = (props: BaseChartProps) => {
  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');
  
  const [legendId] = useState(_uniqueId('legend-'));
  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
	// the component is controlled or not, that value comes from its
	// props or from its internal state.

	const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "OPICables", { startDate: value?.[0], endDate: value?.[1] }],
    queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];

      const result = await BinApiService.getOPICableHistory(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
      return result;
    },
    refetchInterval: Infinity, retry: false, keepPreviousData: true,
    enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null,
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);

  return (<div>
    {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
    <Skeleton loading={chartQuery.data == null}>
      {chartQuery.data && <>
        <section style={{ width: "100%", height: props.chartHeight ?? "480px", display: "flex", justifyContent: "center" }}>
          <Line options={{
            responsive: true,
            animation: false,
            spanGaps: false,
            maintainAspectRatio: false,
            datasets: {
              line: {
                pointRadius: 0 // disable for all `'line'` datasets
              }
            },
            scales: {
              x: {
                type: "time",
                time: {
                  minUnit: 'day',
                  displayFormats: {
                    //day: 'l'
                  }
                },
              },
              y: {
                min: props.min ?? undefined,
                max: props.max ?? undefined,
                title: {
                  font: {
                    size: 16,
                  },
                  display: true,
                  text: "Temperature °F",
                }
              }
            },
            plugins: {
              annotation: {
                annotations: [
                  ...modeHistory.annotations,
                  ...fanHeaterChangeHistory.annotations,
                ],
              },
              legend: {
                position: 'top' as const,
                display: false,
              },
              // @ts-ignore
              htmlLegend: {
                // ID of the container to put the legend in
                containerID: `legend-container-temp-opi${legendId}`,
              },
              title: {
                display: true,
                text: 'Temperature Cables',
              },

              tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: tooltipItemRound,
                },
              },

            },
          }}
            // @ts-ignore
            data={{
              datasets: chartQuery.data?.map(dataset => {
                return {
                  ...dataset,
                  data: dataset.data,
                  label: dataset.label!,
                  parsing: {
                    yAxisKey: 'temperatureF',
                  }
                }
              })
            }} plugins={[htmlLegendPlugin]} />
        </section>
        <section id={`legend-container-temp-opi${legendId}`}></section>
      </>
      }
    </Skeleton>

  </div>);
}

export const TemperatureCableBinSenseChart = (props: BaseChartProps) => {
  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');
  
  const [legendId] = useState(_uniqueId('legend-'));
  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
	// the component is controlled or not, that value comes from its
	// props or from its internal state.

	const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);

  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "BinSenseCables", { startDate: value?.[0], endDate: value?.[1] }],
    queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];

      const result = await BinApiService.getBinSenseCableHistory(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
      return result;
    },
    refetchInterval: Infinity, retry: false, keepPreviousData: true,
    enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null,
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);

  return (<div>
    {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
    <Skeleton loading={chartQuery.data == null}>
      {chartQuery.data && <>
        <section style={{ width: "100%", height: props.chartHeight ?? "480px", display: "flex", justifyContent: "center" }}>
          <Line options={{
            responsive: true,
            animation: false,
            spanGaps: false,
            maintainAspectRatio: false,
            datasets: {
              line: {
                pointRadius: 0 // disable for all `'line'` datasets
              }
            },
            scales: {
              x: {
                type: "time",
                time: {
                  minUnit: 'day',
                  displayFormats: {
                    //day: 'l'
                  }
                },
              },
              y: {
                min: props.min ?? undefined,
                max: props.max ?? undefined,
                title: {
                  font: {
                    size: 16,
                  },
                  display: true,
                  text: "Temperature °F",
                }
              }
            },
            plugins: {
              annotation: {
                annotations: [
                  ...modeHistory.annotations,
                  ...fanHeaterChangeHistory.annotations,
                ],
              },
              legend: {
                position: 'top' as const,
                display: false,
              },
              // @ts-ignore
              htmlLegend: {
                // ID of the container to put the legend in
                containerID: `legend-container-temp-binsense${legendId}`,
              },
              title: {
                display: true,
                text: 'Temperature Cables',
              },

              tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: tooltipItemRound,
                },
              },

            },
          }}
            // @ts-ignore
            data={{
              datasets: chartQuery.data?.map(dataset => {
                return {
                  ...dataset,
                  data: dataset.data,
                  label: dataset.label!,
                  parsing: {
                    yAxisKey: 'temperatureF',
                  }
                }
              })
            }} plugins={[htmlLegendPlugin]} />
        </section>
        <section id={`legend-container-temp-binsense${legendId}`}></section>
      </>
      }
    </Skeleton>

  </div>);
}


export const OPICableChart = (props: BaseChartProps & {yTitle?: string}) => {
  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');
  
  const [legendId] = useState(_uniqueId('legend-'));
  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
	// the component is controlled or not, that value comes from its
	// props or from its internal state.

	const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);


  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "OPICables", { startDate: value?.[0], endDate: value?.[1] }],
    queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];

      const result = await BinApiService.getOPICableHistory(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
      return result;
    },
    refetchInterval: Infinity, retry: false, keepPreviousData: true,
    enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null,
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);

  return (<div>
    {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
    <Skeleton loading={chartQuery.data == null}>
      {chartQuery.data && <section style={{ width: "100%", height: props.chartHeight ?? "480px", display: "flex", justifyContent: "center" }}>
        <Line options={{
          responsive: true,
          animation: false,
          spanGaps: false,
          resizeDelay: 250,
          maintainAspectRatio: false,
          datasets: {
            line: {
              pointRadius: 0 // disable for all `'line'` datasets
            }
          },
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: 'day',
                displayFormats: {
                  //day: 'l'
                }
              },
            },
            y: {
              min: props.min ?? undefined,
              max: props.max ?? undefined,
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: props.yTitle ?? "Moisture Content (%)",
              }
            }
          },
          interaction: {
            includeInvisible: true,            
          },
          plugins: {
            annotation: {
              annotations: [
                ...modeHistory.annotations,
                ...fanHeaterChangeHistory.annotations,
                ]
            },
            legend: {
              position: 'top' as const,
              display: false,
            },
            // @ts-ignore
            htmlLegend: {
              // ID of the container to put the legend in
              containerID: `legend-OMC-container${legendId}`,
            },
            title: {
              display: true,
              text: 'Moisture Cables',
            },

            tooltip: {
              mode: 'index',
              
              intersect: false,
              callbacks: {
                label: tooltipItemRound,
              },
            },

          },
        }}
          // @ts-ignore
          data={{ datasets: chartQuery.data! }} plugins={[htmlLegendPlugin]} />
      </section>
      }
      <section id={`legend-OMC-container${legendId}`}></section>
    </Skeleton>
  </div>);
}

export const BinSenseCableChart = (props: BaseChartProps & {yTitle?: string}) => {
  const currentDate = dayjs();
  const priorDate = currentDate.subtract(14, 'days');
  
  const [legendId] = useState(_uniqueId('legend-'));
  const isControlled = typeof props.value != 'undefined';
  const [internalValue, setIntervalValue] = useState<RangeValue>([priorDate, currentDate]);

  // Internally, we need to deal with some value. Depending on whether
	// the component is controlled or not, that value comes from its
	// props or from its internal state.

	const value = isControlled ? props.value : internalValue;

  const onDateChange = useCallback((values: RangeValue, formatter: any) => {
    if (props.onChange) {
      props.onChange(values);
    }
    else {
      setIntervalValue(values);
    }
  }, [props.onChange]);


  const chartQuery = useQuery({
    queryKey: [...binDBKeys.bin(props.binId), "charts", "BinSenseCables", { startDate: value?.[0], endDate: value?.[1] }],
    queryFn: async (q) => {
      const now = value?.[1];
      const priorMonth = value?.[0];

      const result = await BinApiService.getBinSenseCableHistory(props.binId, priorMonth?.toISOString()!, now?.toISOString()!, null, q.signal);
      queryClient.removeQueries({ queryKey: q.queryKey.slice(0, -1), type: "inactive" });
      return result;
    },
    refetchInterval: Infinity, retry: false, keepPreviousData: true,
    enabled: (props.binId ?? 0) > 0 && value?.[0] != null && value?.[1] != null,
  });

  const modeHistory = useModeChangeHistory(props.binId, value?.[0], value?.[1]);
  const fanHeaterChangeHistory = useFanHeaterChangeHistory(props.binId, value?.[0], value?.[1]);

  return (<div>
    {!isControlled && <ChartDatePicker startDate={value?.[0]!} endDate={value?.[1]!} onDateRangeChange={onDateChange} />}
    <Skeleton loading={chartQuery.data == null}>
      {chartQuery.data && <section style={{ width: "100%", height: props.chartHeight ?? "480px", display: "flex", justifyContent: "center" }}>
        <Line options={{
          responsive: true,
          animation: false,
          spanGaps: false,
          resizeDelay: 250,
          maintainAspectRatio: false,
          datasets: {
            line: {
              pointRadius: 0 // disable for all `'line'` datasets
            }
          },
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: 'day',
                displayFormats: {
                  //day: 'l'
                }
              },
            },
            y: {
              min: props.min ?? undefined,
              max: props.max ?? undefined,
              title: {
                font: {
                  size: 16,
                },
                display: true,
                text: props.yTitle ?? "Moisture Content (%)",
              }
            }
          },
          interaction: {
            includeInvisible: true,            
          },
          plugins: {
            annotation: {
              annotations: [
                ...modeHistory.annotations,
                ...fanHeaterChangeHistory.annotations,
                ]
            },
            legend: {
              position: 'top' as const,
              display: false,
            },
            // @ts-ignore
            htmlLegend: {
              // ID of the container to put the legend in
              containerID: `legend-BSMC-container${legendId}`,
            },
            title: {
              display: true,
              text: 'Moisture Cables',
            },

            tooltip: {
              mode: 'index',
              
              intersect: false,
              callbacks: {
                label: tooltipItemRound,
              },
            },

          },
        }}
          // @ts-ignore
          data={{ datasets: chartQuery.data! }} plugins={[htmlLegendPlugin]} />
      </section>
      }
      <section id={`legend-BSMC-container${legendId}`}></section>
    </Skeleton>
  </div>);
}
