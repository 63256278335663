import { Tag, Typography } from "antd"
import React from "react"
import { PRIMARY_BLUE_SHIVVERS } from "src/app/theme";

interface CustomTagProps{
    label: string;
    variant?: 'primary' | 'secondary';
    bold?: boolean;
}
const CustomTag : React.FC<CustomTagProps> = ({ label, variant = 'primary', bold=false }) => {
    const bgColor = variant === 'primary' ? PRIMARY_BLUE_SHIVVERS : 'red-inverse'
    return (
    <Tag color={bgColor}>

        <Typography.Text style={{ color: 'white'}} strong={bold}>{label}</Typography.Text>

    </Tag>)
}

export default CustomTag;